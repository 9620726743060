import config from '../../config/config';
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import axios from "axios"
import { authHeader } from '../../utils/authHeader';
import { handleError } from "../../utils/handleCatch";

export const authenticationService = { login, logout, signup, isLogged, updateUser, coopSignup, deleteUser };

function login(data) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/login`, {
            username: data.username,
            password: data.password
        })
            .then(handleResponse)
            .then(checkResponse)
            
            .then((responseData) => {
                localStorage.setItem('user', JSON.stringify(responseData.data));
                return responseData;
            })
    );
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user")
    return (
        axios.post(`${config.baseUrl}/cli/profiles/logout`,
            {},
            { headers: authHeader() })
            .then(handleResponse)
        // .then(checkResponse)
            
    );
}

function signup(data) {
    console.log(data);
    return (
        axios.post(`${config.baseUrl}/cli/profiles/signup`,
            {
                username: data.username,
                password: data.password,
                profile: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    birthDate: data.birthDate,
                    mobilePhone: data.mobilePhone,
                    email: data.username,
                    cards: [{
                        code: data.profileCard,
                        principal: true,
                        enabled: true
                    }],
                    accept1: data.accept1,
                    accept2: data.accept2,
                    accept3: data.accept3,
                    accept4: data.accept4,
                }
            })
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function isLogged() {
    return (
        axios.get(`${config.baseUrl}/cli/profiles/login`,
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem("user", JSON.stringify(responseData.data))
                return responseData;
            })
    );
}

function updateUser(data) {
    console.log(data);
    return (
        axios.put(`${config.baseUrl}/cli/profiles`,
            {
                firstName: data.firstName,
                lastName: data.lastName,
                mobilePhone: data.mobilePhone,
                ssnCode: data.ssnCode,
                // birthDate: data.birthDate,
                cards: [{
                    code: data.profileCard,
                    principal: true,
                    enabled: true
                }],
                // email: data.email,
                // gender: data.gender,
                accept1: data.accept1,
                accept2: data.accept2,
                accept3: data.accept3,
                accept4: data.accept4,
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
            .then((responseData) => {
                localStorage.setItem("user", JSON.stringify(responseData.data))
                return responseData;
            })
    );
}

function coopSignup(data) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/signup`, {
            username: data.username,
            password: data.password,
            profile: {
                accept1: data.accept1,
                accept2: data.accept2,
                accept3: data.accept3,
                accept4: data.accept4,
            }
        })
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function deleteUser() {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/deleteRequest`,
            {},
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
            
            .then((responseData) => {
                return responseData;
            })
    );
}