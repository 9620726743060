/* eslint-disable */

import * as actionTypes from "../actions/actionTypes";
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    user: JSON.parse(localStorage.getItem("user")),
    latitude: null,
    longitude: null,
    showOverlay: false,
    jwt: JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).jwt : null ,
}

const userReducer = createReducer(initialState, {
    [actionTypes.LOGIN]: (state, action) => { state.user = action.user, state.jwt = action.jwt },
    [actionTypes.LOGOUT]: (state, action) => { state.user = action.user },
    [actionTypes.USER_UPDATE]: (state, action) => { state.user = action.user },
    [actionTypes.IS_LOGGED]: (state, action) => { state.user = action.user },
    [actionTypes.COORDINATE]: (state, action) => {
        state.latitude = action.latitude,
        state.longitude = action.longitude
    },
    [actionTypes.OVERLAY]: (state, action) => { state.showOverlay = action.showOverlay },
})

export default userReducer;

