import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import { handleError } from "../../utils/handleCatch";
import config from '../../config/config';
import axios from "axios"

export const storesService = {
    stores, states, storesByDelivery, storesCoordinate
};

function stores(page, query) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce?s=${config.maxSize}&p=${page}&q=${query}`)
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function storesCoordinate(page, lat, lon, query) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce?s=${config.maxSize}&p=${page}&lat=${lat}&lon=${lon}&q=${query}`)
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function states() {
    return (
        axios.get(`${config.baseUrl}/cli/stores/states`)
            .then(handleResponse)
        //.then(checkResponse)
            
    );
}

function storesByDelivery(state, deliveryMode) {
    return (
        axios.get(`${config.baseUrl}/cli/stores/ecommerce/delivery?delivery=${deliveryMode}&state=${state}`)
            .then(handleResponse)
        // .then(checkResponse)
            
    );
}