import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom"

const headerLink = (props) => {
    let cssClass = props.history.location.pathname === props.path ? "navigation-box navigation-box__active" : "navigation-box";
    return (
        <Link className="link" to={props.path} onClick={props.closeAll} id={props.icon}>
            <div className="navigation-item">
                <div className={cssClass}>
                    <div className="navigation-box__wrapper text-center">
                        <div className="navigation-box__icon">
                            <FontAwesomeIcon icon={props.icon} size="2x" />
                        </div>
                        <span className="d-none d-lg-block">{props.footer}</span>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default withRouter(headerLink);