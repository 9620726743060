import config from "../../../config/config";
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import axios from 'axios'
import { authHeader } from "../../../utils/authHeader";
import { handleError } from "../../../utils/handleCatch";

export const paymentsService = {
    paymenthMethods, removePaymentMethod, boarding, payOrder, startBoarding, nonce, threeDS
}

function paymenthMethods(jwt) {
    return (
        axios.get(`${config.baseUrl}/cli/pay/paymentmethod`,
            {
                headers: authHeader(jwt)
            })
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function removePaymentMethod(jwt) {
    return (
        axios.post(`${config.baseUrl}/cli/pay/paymentmethod/delete`,
            {
                paymentMethodType: "XPAY"
            },
            {
                headers: authHeader(jwt)
            })
            .then(handleResponse)
            .then(checkResponse)
            
    )
}

function boarding(jwt) {
    return (
        axios.get(`${config.baseUrl}/cli/pay/v2/xpay/boarding`, {
            headers: authHeader(jwt)
        })
            .then(handleResponse)
            .then(checkResponse)
            
    );
}
function startBoarding(jwt) {
    return (
        axios.get(`${config.baseUrl}/cli/pay/v2/xpay/start/boarding`, {
            headers: authHeader(jwt)
        })
            .then(handleResponse)
            .then(checkResponse)
            
    );
}
function nonce(orderCode, jwt) {
    return (
        axios.get(`${config.baseUrl}/cli/pay/v2/xpay/nonce?orderCode=` + orderCode, {
            headers: authHeader(jwt)
        })
            .then(handleResponse)
            .then(checkResponse)
            
    );
}
function threeDS(body) {
    return (
        axios.post(`${process.env.REACT_APP_NEXI_BASE_URL}/ecomm/api/recurring/creaNonceRico3DS`,
            body,
            {
                headers: { 'Access-Control-Request-Headers': '*' }
            })
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function payOrder(id, jwt) {
    return (
        axios.get(`${config.baseUrl}/cli/pay/v2/xpay/nonce?orderCode=${id}`, {
            headers: authHeader(jwt)
        })
            .then(handleResponse)
            .then(checkResponse)
            
    );
}