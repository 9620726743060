import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Placeholder from "../../images/product-placeholder.png"
import CounterInput from "../../components/UI/Counter/CounterInput"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactComponent as Down } from "../../images/down.svg"
import { ReactComponent as Up } from "../../images/up.svg"
import './smartlist.styles.scss'
import * as actionCreators from "../../redux/store/actions/index"
import PopupInput from '../../components/UI/Popup/PopupInput'
import Popup from '../../components/UI/Popup/Popup'
import i18n from '../../i18n'
import Modal from "react-bootstrap/Modal"

const NewSmartList = () => {
    const slists = useSelector(state => state.slists.slists);
    const cart = useSelector(state => state.cart.cart);
    const dispatch = useDispatch();
    const [open, setOpen] = useState("");
    const [state, setState] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        dispatch(actionCreators.getSlists())
    }, [])

    const handleOpen = (id) => {
        if (open !== id) {
            setOpen(id);
        } else {
            setOpen("");
        }
    }

    const onChange = (e) => {
        setState({ [e.target.name]: e.target.value })
    }

    const renderCreate = () => {
        return (
            <PopupInput
                action={() => dispatch(actionCreators.createSlist(state.createTitle))}
                title={i18n.t("smartlist.create")}
                button={i18n.t("create")}
                onChange={onChange}
                name="createTitle"
                trigger={
                    <button>
                        Crea nuova
                    </button>
                }
            />
        );
    }

    const renderAbort = (id) => {
        return (
            <Popup
                action={() => dispatch(actionCreators.deleteSlist(id))}
                title={i18n.t("smartlist.delete")}
                button={i18n.t("delete")}
                content={i18n.t("smartlist.delete.question")}
                trigger={
                    <button className='outline'>Elimina <FontAwesomeIcon icon="trash-alt" size="1x" /></button>
                }
            />
        );
    }

    const renderCopy = (id) => {
        return (
            <PopupInput
                action={() => dispatch(actionCreators.copySlist(id, state.copyTitle))}
                title={i18n.t("smartlist.copy")}
                button={i18n.t("copy")}
                onChange={onChange}
                value={state.copyTitle}
                name={"copyTitle"}
                trigger={
                    <button><FontAwesomeIcon icon="copy" /> Copia</button>
                }
            />
        );
    }

    const renderRename = (id) => {
        return (
            <PopupInput
                action={() => dispatch(actionCreators.renameSlist(id, state.selectTitle))}
                title={i18n.t("smartlist.rename")}
                button={i18n.t("rename")}
                onChange={onChange}
                value={state.selectTitle}
                name={"selectTitle"}
                trigger={
                    <button><FontAwesomeIcon icon="edit" />Rinomina</button>

                }
            />
        );
    }


    return (
        <div className='slist-container'>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <div className="slist-modal">
                    <Modal.Header closeButton>
                        <label className='title'>Crea il carrello</label>
                    </Modal.Header>
                    <Modal.Body>
                        <label className='subtitle'>Per poter aggiungere prodotti alla lista bisogna prima creare il carrello.</label>
                        <button
                            className='addToCart'
                            onClick={() => setShowModal(false)}
                        >
                            Chiudi
                        </button>
                    </Modal.Body>
                </div>
            </Modal>
            <div className='slist-title'>
                <label>Le tue liste della spesa</label>
                {renderCreate()}
            </div>
            <div className='list-container'>
                {slists && slists.map((list) => (
                    <div className='list' key={list.id}>
                        <div className='list-header' onClick={() => handleOpen(list.id)}>
                            {list.title}
                            {open === list.id ?
                                <div>
                                    Chiudi dettaglio<Up />
                                </div>
                                :
                                <div>
                                    Mostra dettaglio<Down />
                                </div>
                            }
                        </div>
                        {open === list.id &&
                            <div className='list-items'>
                                <div className='list-items-header'>
                                    {renderRename(list.id)}
                                    {renderCopy(list.id)}
                                </div>
                                <div className='list-items-container'>
                                    {list.itemsCount > 0 && list.items.map((item) => (
                                        <div className='list-items-container-item' key={item.id}>
                                            <img src={item.imageUrl} alt={Placeholder} onError={(e) => { e.target.src = Placeholder }} />
                                            <label>{item.description}</label>
                                            <CounterInput
                                                slists
                                                value={item.amount}
                                                item={item}
                                                increment={() => dispatch(actionCreators.itemUpdate(list.id, item.id, item.amount + 1))}
                                                decrement={() => item.amount !== 1 && dispatch(actionCreators.itemUpdate(list.id, item.id, item.amount - 1))}
                                                attrib3={item.attrib3}
                                                checkIsBooked={() => { return false }}
                                            />
                                            <button className="cart outline" onClick={() => cart ? dispatch(actionCreators.store(cart.id, item.barcode, item.amount, item.um)) : setShowModal(true)}>
                                                <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                                            </button>
                                            <button className="outline" onClick={() => dispatch(actionCreators.itemDelete(list.id, item.id))}>
                                                <FontAwesomeIcon icon="trash-alt" size="1x" />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <div className='list-items-footer'>
                                    {renderAbort(list.id)}
                                    <button
                                        className='addToCart'
                                        onClick={() => cart ? dispatch(actionCreators.addListToCart(list.id)) : setShowModal(true)}
                                    >
                                        Aggiungi tutto al carello
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default NewSmartList