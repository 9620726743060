import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as Trash } from "../../../images/trash-bin.svg"

const counterInput = (props) => {
    const value = !props.slists ?  `${props.value} ${props.item?.purchaseUm?.toLowerCase()}` : `${props.value}`;

    const handleTrash = () => {
        if (props.checkIsBooked()) {
            if (props.attrib3 && props.item.umStep === props.value) {
                return true
            } else if (props.value === 1) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    return (
        <div className="product-detail-counter mx-auto">
            <div>
                <button className="outline" onClick={handleTrash() ? props.remove : props.decrement}>
                            <FontAwesomeIcon icon={handleTrash() ? "trash-alt" : "minus"} size="1x" />
                </button>
            </div>
            <div className="justify-content-center">
                <input className="text-center" style={{ maxWidth: 'unset', width: '100px' }} value={value} disabled></input>
            </div>
            <div>
                <button className="outline" onClick={props.increment}>
                    <FontAwesomeIcon icon="plus" size="1x"/>
                </button>
            </div>
        </div>
    );
}


export default counterInput;