import config from "../../config/config"
import { handleResponse } from "../../utils/handleResponse";
import { checkResponse } from "../../utils/checkResponse";
import { handleError } from "../../utils/handleCatch";
import axios from "axios"


export default function categories() {
    return (
        axios.get(`${config.baseUrl}/cli/categories`)
            .then(handleResponse)
            .then(checkResponse)
            
    );
}