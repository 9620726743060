import React from "react";
import HomeCarousel from "../Home/HomeCarousel/HomeCarousel"
import FeaturedProducts from "./FeaturedProducts/FeaturedProducts";
import HomeTags from "./HomeTags/HomeTags";
import Categories from "../Categories/Categories";
import CategoryTree from "../Categories/CategoryTree/CategoryTree";

export default class Home extends React.Component {
    render() {
        return (
            <div className="page-grid mobile-view-search">
                <div className="grid-left" offsettop={0} offsetbottom={0}>
                    <CategoryTree />
                </div>
                <div className="grid-right">
                    <HomeCarousel />
                    <HomeTags />
                    <FeaturedProducts />
                </div>
            </div>
        );
    }
}
