import React, { useEffect, useState } from "react"
import Placeholder from "../../images/product-placeholder.png"
import CounterInput from "../../components/UI/Counter/CounterInput";
import ProductInfo from "./ProductInfo/ProductInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
import { productsService } from "./Service"
import * as actionCreators from "../../redux/store/actions/index"
import config from "../../config/config";
import { ReactComponent as SlistIcon } from "../../images/slist.svg"
import { ReactComponent as IsInSlistIcon } from "../../images/list.svg"
import { ReactComponent as Frozen } from "../../images/frozen.svg"
import { format, parseISO } from "date-fns";
import CategoryTree from "../Categories/CategoryTree/CategoryTree";
import ProductBreadcrumb from "../../components/UI/Breadcrumb/ProductBreadcrumb";
import './product.styles.scss'
import ReactTooltip from 'react-tooltip'
import Modal from "react-bootstrap/Modal"
import ProductVariants from "../../components/Products/ProductVariants/ProductVariants";
import toaster from "../../utils/toaster";

const Product = ({ match, location }) => {
    const dispatch = useDispatch();
    const [amount, setAmount] = useState(null);
    const [umStep, setUmStep] = useState(null);
    const [isAlreadyInList, setIsAlreadyInList] = useState(false);
    const [info, setInfo] = useState([]);
    const [incremented, setIncremented] = useState(false);
    const [decremented, setDecremented] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [available, setAvailable] = useState(true);
    const [product, setProduct] = useState(null);
    const [productImages, setProductImages] = useState(null);
    const [open, setOpen] = useState(false);
    const user = useSelector(state => state.user.user)
    const cart = useSelector(state => state.cart.cart)
    const favorites = useSelector(state => state.slists.favorites)
    const currentSlist = useSelector(state => state.slists.currentSlist)
    const slists = useSelector(state => state.slists.slists)
    const barcode = match.params.barcode;
    const isDetail = location.state ? location.state.isDetail : null;
    const detailProduct = location.state ? location.state.product : null;
    const storeId = cart && cart.storeId ? cart.storeId : "";

    useEffect(() => {
        setIsInList();
    }, [currentSlist])

    useEffect(() => {
        productsService.fetchByBarcode(barcode, storeId).then(response => {
            const product = response.data;
            let initialAmount = null;
            // check if is a cart item 
            if (detailProduct) {
                if (isDetail) {
                    // TODO usare il product che arriva dal location state
                    if (detailProduct.um === "PZ") {
                        initialAmount = detailProduct.amount;
                    } else {
                        initialAmount = detailProduct.weight;
                    }
                } else {
                    initialAmount = detailProduct.umStep;
                }
            } else {
                initialAmount = product.umStep;
            }
            // check if is a favorite item 
            let isFavorite = false;
            if (favorites) {
                favorites.forEach(item => {
                    if (match.params.barcode === item.barcode) {
                        isFavorite = true;
                    }
                });
            }

            setProduct(product)
            setAmount(initialAmount)
            setUmStep(product.umStep)
            setIsFavorite(isFavorite)
            setAvailable(isDetail ? true : product.available)
            setProductImages(product && product.images
                ?
                product.images.map(item => {
                    return { original: item }
                })
                : null
            )
            setIsInList()

        }).catch((error) => {
            console.log(error);
        })
        productsService.info(barcode).then(response => {
            if (response.data) {
                setInfo(response.data)
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [barcode, isDetail, detailProduct, storeId])



    const setIsInList = () => {
        var isAlreadyInList = false;
        if (currentSlist && currentSlist.items) {
            currentSlist.items.forEach(element => {
                if (element.ref === product?.ref) {
                    isAlreadyInList = true;
                }
            });
        }
        setIsAlreadyInList(isAlreadyInList)
        return isAlreadyInList;
    }

    const renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                return (
                    <div className="promo-container">
                        <div className="promo-title">-{item.promotions[0].value}%</div>
                        <div className="promo-valid">
                            Valida fino al {format(parseISO(item.promotions[0].validTo), "dd/MM")}
                        </div>
                    </div>
                );
            } else {
                return null
            }
        }
    }

    const incrementAmount = () => {
        let newAmount = amount + umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (product && product.maxAmount) {
            if (newAmount <= product.maxAmount) {
                setAmount(newAmount)
            }
        } else {
            setAmount(newAmount)
        }
        if (checkIsBooked()) {
            const cartItem = cart.items.find(element => element.ref === product.ref);
            if (newAmount !== cartItem.amount && !incremented) {
                setIncremented(true);
                toaster.standard("Dopo aver modificato la quantità aggiornare il carrello");
            }
        }
    }

    const decrementAmount = () => {
        let newAmount = amount - umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (amount > umStep) {
            setAmount(newAmount)
        }
        if (checkIsBooked()) {
            const cartItem = cart.items.find(element => element.ref === product.ref);
            if (newAmount !== cartItem.amount && !decremented) {
                setDecremented(true);
                toaster.standard("Dopo aver modificato la quantità aggiornare il carrello");
            }
        }
    }

    const renderPrices = (item) => {
        let result;
        if (item.price !== item.netPrice) {
            result =
                <>
                    <span className="mr-1 text-strike product-price">
                        {`${(item.price * amount).toFixed(2)} \u20AC`}
                    </span>
                    <span className="primary-color product-price ml-4">
                        {`${(item.netPrice * amount).toFixed(2)} \u20AC`}
                    </span>
                </>
        } else {
            result = <span className="product-price">{`${(item.netPrice * amount).toFixed(2)} \u20AC`}</span>
        }
        return result
    }

    const checkIsBooked = () => {
        var isAlreadyBooked = false;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === product?.ref) {
                    isAlreadyBooked = true;
                }
            });
        }
        return isAlreadyBooked;
    }

    if (product) {
        return (
            <Translation>
                {t =>
                    <div className="page-grid mobile-view-search">
                        <Modal show={open} onHide={() => setOpen(false)}>
                            <div className="slist-modal">
                                <Modal.Header closeButton>
                                    <label className="title">Aggiungi prodotto alla lista</label>
                                </Modal.Header>
                                <Modal.Body>
                                    <label className="subtitle">Seleziona la lista a cui vuoi aggiungere il prodotto</label>
                                    <div className="slists">
                                        {slists && slists.map(list => (
                                            <div onClick={() => { dispatch(actionCreators.itemCreate(currentSlist.id, product.barcode, amount)); setOpen(false) }} key={list.id}>
                                                {list.title}
                                            </div>
                                        ))}
                                    </div>
                                </Modal.Body>
                            </div>
                        </Modal>
                        <div className="grid-left" offsettop={0} offsetbottom={0}>
                            <CategoryTree />
                        </div>
                        <div className="grid-right text-center container-fluid mobile-view-search">
                            <div className="breadcrumb-container">
                                <ProductBreadcrumb product={product} />
                            </div>
                            <div className="product-container">
                                <div className="image-container">
                                    <div className="product-detail">
                                        <img src={product.imageUrl ? product.imageUrl : Placeholder} alt=""
                                            onError={(e) => { e.target.src = Placeholder }}
                                        />
                                    </div>
                                </div>
                                <div className="view-container">
                                    {config.ENABLED_SLIST &&
                                        <div className="card-product-slist" style={product.promotions && product.promotions[0] !== null && product.promotions[0] !== undefined ? { justifyContent: 'space-between', alignItems: 'center' } : {}}>
                                            {product.promotions && product.promotions[0] !== null && product.promotions[0] !== undefined &&
                                                <span className="offer">Offerta</span>
                                            }
                                            {user &&
                                                <button className="slist outline"
                                                    onClick={() => currentSlist && isAlreadyInList ? dispatch(actionCreators.itemDelete(currentSlist.id, product.id)) : setOpen(true)}
                                                    style={isAlreadyInList ? { backgroundColor: "#59B031", borderRadius: '8px' } : null}>
                                                    {isAlreadyInList ?
                                                        <IsInSlistIcon />
                                                        :
                                                        <SlistIcon />
                                                    }
                                                </button>
                                            }
                                            {!user &&
                                                <button className="slist outline"
                                                    onClick={() => { document.getElementById('user').click(); }}>
                                                    <SlistIcon />
                                                </button>
                                            }
                                        </div>
                                    }
                                    <div>
                                        <span className="product-detail-ref">Ean Code {product.barcode}</span>
                                    </div>
                                    <div>
                                        <h1 className="product-detail-name cart-product-description">{product.description}</h1>
                                    </div>
                                    {product?.categoryId?.startsWith("04") &&
                                        <div>
                                            <div>
                                                <Frozen data-tip data-for="registerTip" />
                                            </div>
                                            <ReactTooltip id="registerTip" place="top" effect="solid" backgroundColor="#F5F5F5" textColor="black">
                                                Prodotto Surgelato
                                            </ReactTooltip>
                                        </div>
                                    }
                                    {renderPromo(product)}
                                    {product.pricePerUm && product.umPerUm &&
                                        <div>
                                            <h4 className="product-detail-netPrice pt-3 mb-3">
                                                {`${product.pricePerUm.toFixed(2)} \u20AC / ${product.umPerUm} `}
                                            </h4>
                                        </div>
                                    }
                                    {product.price &&
                                        <div className="mb-3">
                                            {renderPrices(product)}
                                        </div>
                                    }
                                    <div className="counter-container">
                                        <CounterInput
                                            value={amount}
                                            item={product}
                                            remove={() => dispatch(actionCreators.remove(cart.id, product.barcode))}
                                            increment={incrementAmount}
                                            decrement={decrementAmount}
                                            checkIsBooked={checkIsBooked}
                                        />
                                        {user && cart &&
                                            <div>
                                                <button
                                                    className="cart outline text-center"
                                                    style={checkIsBooked() && (!incremented && !decremented) ? { backgroundColor: "#59B031", border: 0 } : null}
                                                    onClick={() => {
                                                        cart && dispatch(actionCreators.store(cart.id, product.barcode, amount, product.um));
                                                        setIncremented(false);
                                                        setDecremented(false);
                                                    }
                                                    }>
                                                    <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                                                    {checkIsBooked() ? incremented || decremented ? "Aggiorna carrello" : "Nel carrello" : t("buy")}
                                                </button>
                                            </div>
                                        }
                                        {user && !cart &&
                                            <div>
                                                <button
                                                    className="cart outline"
                                                    onClick={() => {
                                                        dispatch(actionCreators.setProductToAdd(product, amount));
                                                        document.getElementById('shopping-cart').click();
                                                    }}>
                                                    <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                                                    {t("buy")}
                                                </button>
                                            </div>
                                        }
                                        {!user &&
                                            <div>
                                                <button
                                                    className="cart outline"
                                                    onClick={() => {
                                                        dispatch(actionCreators.setProductToAdd(product, amount));
                                                        document.getElementById('user').click();
                                                    }}>
                                                    <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                                                    {t("buy")}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        <ProductVariants item={product} />
                                    </div>
                                </div>
                            </div>
                            <ProductInfo info={info} />
                        </div>
                    </div>
                }
            </Translation>
        );
    }
    else {
        return null;
    }
}

export default Product