import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import { handleError } from "../../../utils/handleCatch";
import axios from "axios"

export const bannersService = {
    banners, bannersHeader, bannersProductList
};

function banners() {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners?category=WEB`)
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function bannersProductList(bannerId, page, size, storeId) {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners/${bannerId}/products?p=${page}&s=${size}&storeId=${storeId}`)
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function bannersHeader(bannerId) {
    return (
        axios.get(`${config.baseUrl}/cli/cms/banners/${bannerId}/headerBanner`)
            .then(handleResponse)
            .then(checkResponse)
            
    );
}