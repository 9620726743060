import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import axios from "axios"
import { authHeader } from "../../../utils/authHeader";

export default function boardingForm() {
    return (
        axios.get(`${config.baseUrl}/cli/pay/bnl/boarding/web`, {
            headers: authHeader()
        })
        .then(handleResponse)
    );
}