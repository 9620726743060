import React from "react"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import "./breadcrumb.styles.scss";

const ProductBreadcrumb = ({ history, second, third }) => {
    const { t } = useTranslation();

    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <span onClick={() => history.push("/")}>{t("home")}</span>
            </Breadcrumb.Item>
            {second &&
                <Breadcrumb.Item>
                    <span>
                        {second}
                    </span>
                </Breadcrumb.Item>
            }
            {third &&
                <Breadcrumb.Item>
                    <span>
                        {third}
                    </span>
                </Breadcrumb.Item>
            }
        </Breadcrumb>
    );
}

export default withRouter(ProductBreadcrumb);