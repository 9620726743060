import React from 'react'
import './terms.styles.scss'

const Terms = () => {
    return (
        <div className='terms'>
            <label className='terms-title'>TERMINI E CONDIZIONI SPESA ON LINE</label>
            <label className='terms-description'>L’Utente è invitato a stampare o a conservare secondo le modalità preferite il presente Contratto. Le condizioni contrattuali sempre valide sono quelle pubblicate sul sito del Servizio e sempre accessibili al seguente indirizzo: https://coop-puglia.smartbip.it/auth/login Il Contratto (come da definizione: contratto a distanza ai sensi dell’art. 50 e ss. Del D. lgs. 6 settembre 2005, n. 206 "Codice del Consumo", come modificato dal Dlgs 21 febbraio 2014 n. 21) stipulato tra Tatò Paride spa e l'Utente, si intende accettato da parte dell'Utente con la validazione dell'email dell'Utente stesso durante la fase di registrazione al Servizio. Tale accettazione si ritiene tacita, se non altrimenti comunicato all'Utente con i mezzi appropriati.</label>

            <label className='terms-title'>DESCRIZIONE GENERALE DEL SERVIZIO</label>
            <label className='terms-description'>Coop spesa on line (“il Servizio”) è basato su un’applicazione web accessibile all’indirizzo coop-puglia.smartbip.it e consente all’Utente di ordinare e pagare la propria spesa on-line e ritirarla presso il Punto di Vendita prescelto tra quelli aderenti all’iniziativa o di riceverla direttamente a casa. Il servizio è fruibile anche da hardware mobile previa installazione su smartphone e/o tablet della relativa applicazione “Coop Spesa on Line” disponibile nei rispettivi Store IOS ed Android.</label><br/>
            <label className='terms-description'>Il Servizio, il dominio e le App ad esso associato sono di proprietà di Tatò Paride spa.</label><br/>
            <label className='terms-description'>Dopo aver scelto il punto vendita preferito, l'Utente può visionare tutti i prodotti acquistabili e cercare prodotti specifici. I prodotti sono divisi in categorie e sottocategorie per facilitare la navigazione e l'individuazione dei prodotti da ordinare. Per ogni prodotto, l'Utente può vederne i dettagli, il prezzo, l'immagine (se presente) e le eventuali offerte attive. Dopo averne impostato la quantità desiderata, l'Utente può aggiungere qualsiasi prodotto nel proprio carrello virtuale. In giornata, o nei giorni successivi ove indicato, (vedi Orario di utilizzo del servizio), l'Utente può inviare al punto vendita il suo ordine per verificare la disponibilità dei prodotti richiesti. Nel minor tempo possibile (vedi Tempi di risposta del punto vendita), il punto vendita notifica (vedi Notifiche) all'Utente la disponibilità (vedi Disponibilità dei prodotti) dei suddetti prodotti. In base alle disponibilità indicate, l'Utente ha facoltà di proseguire con l'ordine e procedere al pagamento, oppure di modificare l'ordine e richiedere nuovamente una verifica delle disponibilità al punto vendita (salvo specifiche condizioni del punto vendita scelto), oppure di annullare completamente l'ordine senza alcun costo aggiuntivo.</label><br/>
            <label className='terms-description'>In tutti i casi, l'Utente ha a disposizione un tempo massimo variabile, calcolato automaticamente dal sistema, per rispondere al punto vendita (vedi Tempi di risposta a disposizione per l'Utente). Oltre il tempo massimo, l'ordine scadrà automaticamente e non sarà più valido.</label><br/>
            <label className='terms-description'>Se l'Utente è soddisfatto delle disponibilità indicate dal punto vendita, può proseguire e pagare l'ordine tramite carta di credito. Dopo aver terminato il pagamento con successo, all'Utente viene notificato il codice di ritiro (vedi Ritiro) da mostrare/consegnare al punto vendita all’atto del ritiro materiale della spesa. Dopo aver effettuato il pagamento, l'Utente ha il diritto di annullare completamente l'ordine in qualsiasi momento prima dell'emissione dello scontrino fiscale. In tal caso, è facoltà del punto vendita decidere se addebitare o meno il valore di particolari prodotti già imbustati (vedi Ordini annullati e ritiro del giorno dopo). Eventuali somme addebitate e relativa lista dei prodotti saranno comunque notificati all'Utente. L'Utente ha il diritto di poter ritirare i prodotti addebitati, relativi ad una spesa annullata, negli orari di apertura del Punto di Vendita e comunque non oltre il periodo di giacenza garantita dal Punto di Vendita (vedi Ordini annullati e ritiro del giorno dopo). All’Utente saranno in ogni caso addebitati i costi di fruizione del Servizio (vedi Costo del Servizio).</label><br/>
            <label className='terms-description'>Il Servizio offre all’Utente anche la possibilità di ricevere l'ordine direttamente a casa (vedi Consegna a domicilio e sconti applicati). Prima del pagamento, l'Utente ha la possibilità di scegliere se ritirare l'ordine presso il punto vendita scelto oppure se riceverlo comodamente a casa. In tal caso, all'Utente viene richiesto di inserire tutti i dati necessari per la consegna.</label><br/>
            
            
            <label className='terms-title'>CONDIZIONI GENERALI</label>
            <label className='terms-subtitle'>Effettuando un ordine, l'Utente dichiara:</label>
            <label className='terms-description'>Di essere un consumatore secondo quanto previsto all’art. 3 del Codice del Consumo;</label><br/>
            <label className='terms-description'>di essere maggiorenne;</label><br/>
            <label className='terms-description'>che i dati dallo stesso forniti per l’esecuzione del Contratto sono corretti e veritieri;</label><br/>
            <label className='terms-description'>di aver preso visione di tutte le indicazioni a lui fornite durante la procedura d’acquisto, e di accettare integralmente le condizioni generali e di pagamento indicate nella procedura di acquisto e nel presente Contratto;</label><br/>
            <label className='terms-description'>di essere edotto che la transazione commerciale viene posta in essere con il Punto di Vendita.</label><br/>

            <label className='terms-subtitle'>Prezzi</label>
            <label className='terms-description'>Tutti i prezzi esposti dal Punto di Vendita nei listini disponibili per il tramite del Servizio sono comprensivi di IVA. I prodotti sono prenotati al prezzo indicato sul sito al momento dell’invio dell'ordine ma resta inteso che il prezzo potrebbe variare fino al momento dell’emissione dello scontrino fiscale che resta il momento reale di definizione del prezzo d’acquisto definitivo del prodotto.</label><br/>
            <label className='terms-description'>L’eventuale variazione del prezzo è dovuto esclusivamente all’eventuale aggiornamento del listino prezzi nel periodo che intercorre tra la prenotazione della spesa e l’emissione dello scontrino fiscale.</label><br/>
            <label className='terms-description'>Relativamente agli articoli in offerta è sempre indicato il periodo di validità della stessa. Se la data richiesta di ritiro o consegna al domicilio dell’articolo in offerta è successiva al periodo stesso dell’offerta il prezzo di vendita non sarà quello scontato evidenziato ma quello di listino aggiornato al momento dell’emissione dello scontrino.</label><br/>
            
            <label className='terms-subtitle'>Costo del Servizio</label>
            <label className='terms-description'>Il costo del Servizio potrebbe variare in base alla città, al Punto di Vendita selezionato e all'importo ordinato. Tale importo viene automaticamente addebitato insieme al prezzo della spesa. Il costo del Servizio è esplicitato all’Utente al momento della scelta del Punto di Vendita presso cui disporre l’acquisto della Spesa.</label><br/>
            <label className='terms-description'>In genere, se non diversamente specificato:</label><br/>
            <label className='terms-description'>il costo del ritiro presso il punto vendita prescelto è gratuito, € 0 (zero/00).</label><br/>
            <label className='terms-description'>il costo base della consegna può variare da un minimo di € 0 (zero/00), consegna gratuita, ad un massimo di € 3,50 (tre/50). Per ulteriori dettagli sui costi della consegna (vedi Consegna a domicilio).</label><br/>
            <label className='terms-description'>Il costo base di consegna comprende il trasporto di 2 fardelli d’acqua pari a 12 bottiglie.</label><br/>
            <label className='terms-description'>Eventuali richiesta di ulteriori fardelli comporterà l’addebito per ognuno di 0,50€. Il numero massimo di fardelli acquistabile è pari a 5 (cinque).</label><br/>

            <label className='terms-subtitle'>Importo minimo di spesa</label>
            <label className='terms-description'>L'importo minimo per effettuare un ordine tramite il Servizio può variare ed è sempre e comunque visibile nel riepilogo del carrello virtuale prima della conferma dell’acquisto.</label><br/>

            <label className='terms-subtitle'>Carrello virtuale</label>
            <label className='terms-description'>Tutti i prodotti che l'Utente intende ordinare devono essere aggiunti al carrello virtuale. In qualsiasi momento, prima dell'ordine, l'Utente può modificare ed eliminare i prodotti aggiunti al carrello stesso. Dal carrello virtuale è sempre visibile il costo attuale totale dei prodotti inseriti in quel dato momento.</label><br/>

            <label className='terms-subtitle'>Ordine</label>
            <label className='terms-description'>Un ordine presso un Punto di Vendita è diviso in tre fasi principali:</label><br/>
            <label className='terms-description'>Pre-ordine: è il primo invio della lista dei prodotti al punto vendita e si conclude quando il punto vendita risponde notificando all'Utente la disponibilità per ogni singolo prodotto;</label><br/>
            <label className='terms-description'>Ordine e pagamento: l'Utente, ricevuta la disponibilità dal Punto di Vendita, procede con l'invio definitivo dell'ordine pagando l'importo indicato nel carrello virtuale. Tale fase si conclude quando all'Utente viene inviato il codice di ritiro spesa;</label><br/>
            <label className='terms-description'>Consegna o Ritiro: nel caso della consegna a domicilio, l'Utente riceve direttamente all'indirizzo indicato l'ordine consegnato dal fattorino, il quale si occuperà di perfezionare l'ordine. Nel caso del ritiro, l'Utente si reca fisicamente presso il punto vendita selezionato e notifica all'addetto il proprio codice di ritiro spesa. Tale fase si conclude quando l'addetto del punto vendita consegna la Spesa all'Utente, il quale dispone eventualmente in questa fase i necessari controlli di conformità tra quanto acquistato e quanto ricevuto. Con il Ritiro l’Acquisto si intende definitivamente perfezionato ed accettato.</label><br/>
            <label className='terms-description'>Prima del pagamento, l'Utente ha il diritto di modificare i prodotti nel carrello e di richiedere al punto vendita il controllo sulla disponibilità di nuovi prodotti. Tali condizioni possono variare in base al punto vendita scelto.</label><br/>
            
            <label className='terms-subtitle'>Disponibilità dei prodotti</label>
            <label className='terms-description'>La disponibilità dei prodotti che è possibile aggiungere al carrello varia in base al Punto di Vendita selezionato. Tale disponibilità non è verificata finché non viene inviata una richiesta di pre-ordine al Punto di Vendita selezionato. Il Servizio restituisce (a mezzo notifica push e/o SMS e/o e-mail e/o messaggio di sistema) la conferma totale o parziale del pre-ordine. Con l’eventuale successiva accettazione dell’Utente l’ordine si intende definitivamente formalizzato ed assume carattere impegnativo per il Punto di Vendita. L’Utente potrà sempre annullare l’ordine in conformità alle specifiche Condizioni (vedi Revoca dell’Acquisto – Addebiti praticati dal Punto di Vendita).</label><br/>

            <label className='terms-subtitle'>Orario di utilizzo del servizio</label>
            <label className='terms-description'>Gli orari di operatività del Servizio per effettuare un ordine, variano in base al punto vendita selezionato. Ogni punto vendita stabilisce in maniera indipendente i giorni e gli orari in cui è possibile ordinare e ritirare una spesa. Gli orari di operatività del Servizio sono esplicitati all’Utente e selezionabili durante la fase di prenotazione della spesa.</label><br/>

            <label className='terms-subtitle'>Tempi per il ritiro della spesa</label>
            <label className='terms-description'>La spesa ordinata deve essere ritirata nella stessa giornata in cui è stato effettuato l’ordine, nella fascia oraria indicata durante l'ordine. Il ritiro al giorno successivo è permesso solo nel caso di spese annullate con prodotti deperibili comunque addebitati o per i punti vendita che prevedono il ritiro nei giorni successivi all’ordine.</label><br/>
            
            <label className='terms-subtitle'>Tempi di risposta del punto vendita</label>
            <label className='terms-description'>Il tempo di attesa dell'Utente per la verifica della disponibilità dei prodotti da parte del punto vendita può variare in base al numero di ordini in corso e/o all'affluenza di clienti nel punto vendita stesso.</label><br/>

            <label className='terms-subtitle'>Tempi di risposta a disposizione per l'Utente</label>
            <label className='terms-description'>Dopo aver inviato un ordine e aver ricevuto la prima risposta dal punto vendita, l'Utente ha a disposizione un tempo massimo variabile, calcolato automaticamente dal sistema, per modificare il carrello e richiedere una ulteriore verifica sulla disponibilità dei prodotti al punto vendita (salvo specifiche condizioni del punto vendita scelto), oppure per procedere al pagamento dell'ordine stesso. Passato il tempo massimo, l'ordine sarà automaticamente invalidato e scadrà.</label><br/>

            <label className='terms-subtitle'>Notifiche</label>
            <label className='terms-description'>L'Utente viene costantemente aggiornato sullo stato di avanzamento del proprio ordine tramite comunicazioni digitali. Tali comunicazioni possono avvenire tramite: email, sms e/o messaggi all'interno del Servizio. Solo in casi eccezionali, l'Utente può essere contattato telefonicamente direttamente dal punto vendita.</label><br/>

            <label className='terms-subtitle'>Ordini annullati e ritiro del giorno dopo</label>
            <label className='terms-description'>Dopo aver pagato un ordine, è diritto dell'Utente poterlo annullare completamente prima dell'emissione dello scontrino fiscale. In tal caso, il punto vendita ha la facoltà di addebitare comunque all'Utente il costo di eventuali prodotti freschi, deperibili, da banco o a peso che non possono più essere rimessi in vendita. In tale circostanza, all'Utente verrà inviata una notifica con il dettaglio degli eventuali costi addebitati. L'Utente può comunque ritirare detti prodotti, giacché addebitati, entro il giorno seguente all'annullamento (usando lo stesso codice di ritiro).</label><br/>
            <label className='terms-description'>Per gli ordini annullati i cui prodotti possono essere rimessi in vendita nella loro totalità, non viene in nessun caso addebitato all'Utente alcun costo relativo ai prodotti.</label><br/>
            <label className='terms-description'>Quindi, nel caso di ordine annullato, all'Utente viene addebitata la somma dovuta a prodotti non più rivendibili.</label><br/>
            <label className='terms-description'>L’eventuale rimborso verrà sempre effettuato sullo stesso mezzo utilizzato al momento dell’ordine/ acquisto.</label><br/>

            <label className='terms-subtitle'>Pre-pagamenti e pagamenti</label>
            <label className='terms-description'>Il Servizio offre sicure modalità di pagamento tramite carta di credito per esclusivo tramite servizio terzi: BNL Positivity. I dati delle carte di credito non vengono in alcun modo memorizzati né gestiti, in quanto i servizi di monetica sono stati interamente delegati a soggetti Abilitati.</label><br/>
            <label className='terms-description'>Vengono accettate le carte dei principali circuiti nazionali e internazionali. Durante il pagamento dell'ordine, viene effettuato sulla carta di credito dell'Utente un blocco dell'importo indicato nel carrello in quel momento (tecnicamente una pre-autorizzazione di pagamento). L'importo viene effettivamente addebitato nella sua totalità, o in parte se la disponibilità confermata dal Punto di Vendita ed accettata dall’Utente dovesse essere inferiore alle quantità pre-ordinate, quando la spesa viene materialmente ritirata dall'Utente presso il punto vendita.</label><br/>
            <label className='terms-description'>Quindi, l'importo della spesa viene realmente addebitato sulla carta dell'Utente solo al momento della consegna e non prima. Eccezione viene fatta solo per gli ordini annullati, per i quali il punto vendita, in base alle proprie specifiche condizioni, può addebitare quanto previsto al paragrafo Ordini annullati e ritiri del giorno dopo entro il giorno di annullamento.</label><br/>

            <label className='terms-subtitle'>Garanzia di qualità e freschezza</label>
            <label className='terms-description'>I punti vendita aderenti al Servizio, si impegnano a garantire la massima qualità sia del servizio da loro direttamente offerto che dei prodotti imbustati per l'Utente.</label><br/>
            <label className='terms-description'>I punti vendita aderenti al Servizio, si impegnano, inoltre, a imbustare i prodotti con la scadenza più lontana nel tempo rispetto al giorno dell'ordine e in base alle disponibilità del momento.</label><br/>

            <label className='terms-subtitle'>Prodotti a peso variabile</label>
            <label className='terms-description'>I prodotti a peso variabile (ad es. frutta, verdura, carne, pesce, prodotti di gastronomia e formaggi) vengono proposti dal Servizio in misure e pesi indicativi. Il peso effettivo si determina solamente al momento della preparazione del prodotto, e può variare fino al 10% in più o in meno rispetto a quanto indicativamente proposto dal Servizio. L'esatto prezzo dei prodotti venduti a peso verrà comunicato all’atto del ritiro della Spesa, mentre l’importo pre-impegnato sulla carta di credito sarà di conseguenza adeguato ed il prezzo complessivo della Spesa sarà effettivamente addebitato solo in quel momento.</label><br/>

            <label className='terms-subtitle'>Prodotti a quantità minima e quantità massima</label>
            <label className='terms-description'>Alcuni prodotti, in particolare tra quelli a peso variabile, possono essere ordinati solo con quantità minime. Tali quantità sono indicate, di volta in volta, durante l'aggiunta del prodotto nel carrello virtuale.</label><br/>
            <label className='terms-description'>E’ possibile acquistare fino ad un massimo di 30 pezzi di ogni singolo articolo nel rispetto degli acquisti per uso familiare e non ingrosso. Nel caso dei fardelli d’acqua il numero massimo acquistabile è di 5 (cinque) pari a 30 bottiglie. Il costo di trasporto delle prime 12 bottiglie (2 fardelli) è compreso nel costo del servizio mentre per i fardelli eccedenti verrà applicato un extra addebito pari a 0,50€ a fardello.</label><br/>

            <label className='terms-subtitle'>Consegna a domicilio e sconti applicati</label>
            <label className='terms-description'>Il Servizio consegna la spesa solamente nelle zone dove è presente, tramite propri mezzi e proprio personale o tramite mezzi e personale di partner individuati in zona.</label><br/>
            <label className='terms-description'>La spesa verrà consegnata nelle fasce orarie indicate e disponibili durante l'ordine.</label><br/>
            <label className='terms-description'>Il servizio di consegna viene, inoltre, garantito solo per le abitazioni raggiungibili con i mezzi in dotazione (sono escluse, ad esempio, le abitazioni che siano raggiungibili solo tramite strade sterrate o esclusivamente pedonali). Il personale si limiterà a consegnare la merce sul pianerottolo davanti alla porta d'ingresso dell'abitazione. Per ragioni organizzative e di efficienza del servizio, la spesa non potrà essere verificata o controllata dall'Utente al momento della consegna. Per qualsiasi anomalia riscontrata contattare il supporto ed ottenere assistenza via email: info@tatoparide.it oppure telefonicamente al numero verde 800626181 o al numero di telefono del punto vendita dove si dovrà effettuare il ritiro della spesa (numeri disponibili sul sito istituzionale o da richiedere al numero verde).</label><br/>
            <label className='terms-description'>Il Servizio non sarà responsabile per eventuali ritardi occorsi durante il trasporto. Parimenti il Servizio non potrà essere ritenuto responsabile per qualsiasi perdita, danno, errata o mancata consegna causati da eventi e/o cause di forza maggiore o caso fortuito, quali a titolo indicativo ma non esaustivo: calamità naturali, avverse condizioni atmosferiche (quali, ad esempio, intense nevicate), eventuali scioperi (dei propri o di altri dipendenti), incidenti a mezzi di trasporto, esplosioni, o ogni altra causa, simile o diversa.</label><br/>
            <label className='terms-description'>Il costo della consegna non è compreso nel prezzo dei prodotti ed è composto da un costo base, salvo diverse indicazioni o promozioni in corso:</label><br/>
            <label className='terms-description'>il costo base della consegna a domicilio dipende dalla città e dallo specifico Punto di Vendita e può variare da un minimo di € 0 (zero/00), consegna gratuita, ad un massimo di € 3,50 (tre/50) e comprende il trasporto di 2 fardelli d’acqua. Gli eventuali fardelli in eccesso comporteranno un addebito di ulteriori 0,50€ a fardello. Il numero massimo di fardelli ordinabili è pari a 5 (cinque).</label><br/>
            <label className='terms-description'>Tutti i predetti costi sono IVA inclusa.</label><br/>
            <label className='terms-description'>Durante la scelta della consegna a domicilio, all'Utente verranno chiesti tutti i dati necessari: indirizzo e numero civico, un numero di cellulare di riferimento, e sarà possibile inserire alcune NOTE ritenute utili dall’utente per agevolare la consegna stessa.</label><br/>
            <label className='terms-description'>Per la consegna è necessaria la presenza dell'Utente o di un suo incaricato all'indirizzo ed all'orario indicato nell'ordine. Se l'Utente (o la persona incaricata) non è presente, l'ordine si intenderà perfezionato e all'Utente verrà addebitato il costo della consegna e quello dei prodotti. Nel caso di mancata consegna, l'Utente ha il diritto di ritirare i prodotti comunque addebitati il giorno seguente all'ordine.</label><br/>
            <label className='terms-description'>Alle spese prenotate sia per il ritiro in punto vendita che per la consegna al domicilio sarà applicato il costo delle buste vigente in cassa.</label><br/>
            <label className='terms-description'><span style={{fontFamily: "FuturaStd-Bold"}}>N.B.</span> Per i sacchetti compostabili e biodegradabili rifarsi allo specifico paragrafo Sacchetti compostabili e biodegradabili.</label><br/>

            <label className='terms-subtitle'>Rispetto delle norme igienico sanitarie per il trasporto di alimenti</label>
            <label className='terms-description'>Per il trasporto della spesa dal punto vendita a casa dell’Utente, usiamo furgoni e/o contenitori certificati per il trasporto degli alimenti. I furgoni sono isotermici e con cella frigorifera. I contenitori sono isotermici. In questo modo, garantiamo il mantenimento della catena del freddo per portare i prodotti ancora freschi direttamente nelle mani dell’Utente.</label><br/>
            <label className='terms-description'>Inoltre, i nostri fattorini sono stati tutti formati con specifico corso per alimentarista, in questo modo affidiamo la merce in mano a persone esperte e garantiamo una elevata qualità del servizio.</label><br/>

            <label className='terms-subtitle'>I coupon</label>
            <label className='terms-description'>I coupon sono dei codici collegati a delle promozioni che possono essere attivate di tanto in tanto sul servizio. Le promozioni possono essere di varia natura, ad esempio, sconto sulla consegna o sui prodotti (fisso o percentuale). Possono essere subordinate a specifiche condizioni come, ad esempio, un importo minimo di acquisto oppure ad un'azione specifica come invitare un amico ad usare il servizio. La validità dei coupon può essere limitata ad un intervallo di tempo ben preciso che verrà sempre indicato all’Utente. Oltre tale intervallo, il coupon risulterà scaduto e quindi non valido.</label><br/>
            <label className='terms-description'>È facoltà del Servizio, creare nuovi coupon, revocarli e/o modificarne le caratteristiche (ad esempio: periodo di validità) in qualsiasi momento.</label><br/>
            <label className='terms-description'>È facoltà del Servizio, revocare, in qualsiasi momento, uno o più coupon per uno specifico Utente.</label><br/>

            <label className='terms-subtitle'>Responsabilità dell'Utente</label>
            <label className='terms-description'>Le credenziali di accesso al Servizio sono personali e non condivisibili con altri utenti. È responsabilità dell'Utente mantenere in sicurezza le proprie credenziali di accesso ed evitare che altri utenti le utilizzino senza permesso per accedere al Servizio.</label><br/>

            <label className='terms-subtitle'>Supporto e assistenza</label>
            <label className='terms-description'>In caso di problemi tecnici, malfunzionamenti o qualsiasi altro problema collegato direttamente alla fruibilità del Servizio, l'Utente può contattare il supporto ed ottenere assistenza via email: <a href='mail: info@tatoparide.it'>info@tatoparide.it</a>, telefonicamente: <a href='tel:+39 800626181'>800626181</a>.</label><br/>

            <label className='terms-subtitle'>Trattamento dei dati personali</label>
            <label className='terms-description'>I dati dell’Utente sono trattati da Tatò Paride spa conformemente a quanto previsto dalla normativa in materia di protezione dei dati personali, come specificato nell'informativa fornita nella sezione <a href='http://www.coopmasterpuglia.it/privacy-policy-2/' target='_blank'>Privacy Policy</a>.</label><br/>

            <label className='terms-subtitle'>Specificità del Servizio di consegna a domicilio</label>
            <label className='terms-description'>Per consegnare la merce al domicilio dell’Utente, viene impiegato solo personale specializzato con qualifica e formazione da alimentarista.</label><br/>

            <label className='terms-subtitle'>Abbigliamento del personale</label>
            <label className='terms-description'>Il nostro personale è munito di:</label><br/>
            <label className='terms-description'>Badge di riconoscimento;</label><br/>

            <label className='terms-subtitle'>Diritti e doveri dell’Utente specifici per la consegna a domicilio e per il ritiro presso l'esercente</label>
            <label className='terms-description'>Durante la fase di consegna della merce, prima di accettarla da parte del nostro personale, l’utente dovrà verificare che non ci siano anomalie, in particolare alle buste e/o ai sigilli apposti sulle stesse.</label><br/>
            <label className='terms-description'>È diritto dell’Utente rifiutare la consegna nel caso riscontri sigilli manomessi e/o buste rovinate. In tal caso, verrà avviata la procedura di gestione reclami per accertare il problema ed, eventualmente, rimborsare interamente l’Utente.</label><br/>
            <label className='terms-description'>Invece, accettando la merce senza alcuna segnalazione, l’Utente accerta, di fatto, il buono stato sia delle buste che dei relativi sigilli e l'ordine si intende in quel momento definitivamente perfezionato in acquisto in presenza.</label><br/>
            <label className='terms-description'>Per l'opzione ritiro presso l'esercente, l’Utente dovrà verificare che non ci siano anomalie, in particolare che all'interno delle buste ci sia la merce ordinata. Il ritiro delle buste da parte dell'utente perfezionerà in via definitiva l'ordine effettuato online e l'acquisto si intenderà concluso in presenza.</label><br/>

            <label className='terms-subtitle'>Reclami e anomalie</label>
            <label className='terms-description'>Eventuali reclami da parte dell'Utente:</label><br/>
            <label className='terms-description'>legati a problemi tecnici del Servizio: vanno inoltrati o via email a <a href='mail: info@tatoparide.it'>info@tatoparide.it</a>, telefonicamente a <a href='tel:+39 800626181'>800626181</a>;</label><br/>
            <label className='terms-description'>legati ad anomalie tra i prodotti ricevuti a domicilio e ciò che è riportato all'interno dello scontrino fiscale (o fattura) consegnato: vanno inoltrati via email a <a href='mail: info@tatoparide.it'>info@tatoparide.it</a>, telefonicamente a <a href='tel:+39 800626181'>800626181</a>;</label><br/>
            <label className='terms-description'>legati ad anomalie tra i prodotti ritirati presso il punto vendita e ciò che è riportato all'interno dello scontrino fiscale (o fattura) ricevuto: vanno presentati direttamente presso il punto vendita in questione;</label><br/>
            <label className='terms-description'>legati alla qualità dei prodotti sia ritirati che consegnati: vanno presentati direttamente presso il punto vendita in questione.</label><br/>
            <label className='terms-description'>Nel caso specifico di reclami dovuti a problemi o anomalie durante i pagamenti effettuati tramite uno dei sistemi offerti dal Servizio, è necessario presentare e inviare: lo scontrino di acquisto (nel caso in cui si fosse già comunque perfezionato l'acquisto) e tutte le necessarie informazioni per tracciare in maniera univoca il pagamento: data ordine, numero ordine, metodo di pagamento, nome e cognome a cui è intestato il metodo di pagamento usato, importo della transazione, dispositivo (pc, mac, tablet o altro), sistema operativo e browser utilizzati per effettuare il pagamento e una descrizione precisa del motivo del reclamo.</label><br/>
            <label className='terms-description'>Per effettuare un reclamo per un pagamento perfezionato e concluso è assolutamente necessario presentare lo scontrino fiscale di acquisto (o fattura) rilasciato in cassa oppure consegnato a domicilio. In assenza della prova fiscale di acquisto, il reclamo non sarà preso in considerazione.</label><br/>
            <label className='terms-description'>Tutti i reclami devono essere presentati entro, e non oltre, le 72 ore successive al ritiro o alla consegna della spesa in questione.</label><br/>

            <label className='terms-subtitle'>Sacchetti compostabili e biodegradabili</label>
            <label className='terms-description'>Dal 01/01/2018 è entrata in vigore la legge n. 123 del 3 agosto 2017 che impone l’utilizzo di sacchetti compostabili e biodegradabili idonei al contatto alimentare per il confezionamento dei prodotti e l’impossibilità di cessione a titolo gratuito, con obbligo di esporre i costi dei suddetti sacchetti sullo scontrino d’acquisto.</label><br/>
            <label className='terms-description'>Nel rispetto di tale legge, sarà addebitato all’Utente, e riportato sullo scontrino fiscale di acquisto (o fattura), un costo pari a € 0,02 (zero/02centesimi) per ogni sacchetto utilizzato.</label><br/>
            
            <label className='terms-title'>COMUNICAZIONI</label>
            <label className='terms-description'>Ogni comunicazione potrà essere indirizzata a Tatò Paride spa Via Foggia 177, 76121 Barletta (BT).</label><br/>
            
            <label className='terms-title'>LEGGE APPLICABILE E FORO COMPETENTE</label>
            <label className='terms-description'>Il presente Contratto è regolato dalla legge italiana. Qualsiasi controversia relativa all’applicazione o interpretazione del presente Contratto sarà risolta davanti all'Autorità giudiziaria competente in base alla normativa applicabile in Italia. Foro competente sarà quello di Trani.</label><br/>
            
            <label className='terms-title'>VARIE</label>
            <label className='terms-description'>Qualora una clausola di questo Contratto risulti essere nulla o inefficace, l'eventuale nullità o inefficacia non si estenderà alle restanti clausole contrattuali.</label><br/>
            <label className='terms-description'>Tatò Paride spa potrà modificare in qualsiasi momento il contenuto del Contratto comunicando le modifiche via email o pubblicandole sul proprio sito internet. Salvo che non sia diversamente specificato, tutti i nuovi termini e condizioni del Contratto saranno automaticamente efficaci decorsi 30 (trenta) giorni dalla data di pubblicazione.</label><br/>
            <label className='terms-description'>Ai sensi degli articoli 1341 e 1342 del Codice Civile, l'Utente dichiara espressamente di aver letto, compreso e accettato i seguenti articoli del presente Contratto: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26 Legge applicabile e foro competente, Varie.</label><br/>
        </div>
    )
}

export default Terms