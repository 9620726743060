import React from "react"
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";

import "./profile-menu.styles.scss";

const ProfileMenu = ({ history }) => {
    const { t } = useTranslation();
    const pathname = history.location.pathname;
    return (
        <div className="profile-menu">
            <div className={`profile-menu-link ${pathname === "/profile" ? "active" : ""}`}>
                <Link className="link" to="/profile">
                    {t("profileContainer.preview")}
                </Link>
            </div>
            <div className={`profile-menu-link ${pathname === "/profile/addresses" ? "active" : ""}`}>
                <Link className="link" to="/profile/addresses">
                    {t("profileContainer.shippingAddress")}
                </Link>
            </div>
            <div className={`profile-menu-link ${pathname === "/profile/billing" ? "active" : ""}`}>
                <Link className="link" to="/profile/billing">
                    {t("profileContainer.billingAddress")}
                </Link>
            </div>
            <div className={`profile-menu-link ${pathname === "/profile/password" ? "active" : ""}`}>
                <Link className="link" to="/profile/password">
                    {t("profileContainer.passwordChange")}
                </Link>
            </div>
            <div className={`profile-menu-link ${pathname === "/profile/orders" ? "active" : ""}`}>
                <Link className="link" to="/profile/orders">
                    {t("profileContainer.orders")}
                </Link>
            </div>
            <div className={`profile-menu-link ${pathname === "/profile/smartlists" ? "active" : ""}`}>
                <Link className="link" to="/profile/smartlists">
                    {t("profileContainer.slists")}
                </Link>
            </div>
            <div className={`profile-menu-link ${pathname === "/profile/payments" ? "active" : ""}`}>
                <Link className="link" to="/profile/payments">
                    {t("profileContainer.payments")}
                </Link>
            </div>
        </div>
    );
}

export default withRouter(ProfileMenu);