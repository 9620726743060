import React from "react";
import config from "../../../../config/config";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';
import * as actionCreators from "../../../../redux/store/actions"
import { ReactComponent as PinIcon } from '../../../../images/pin.svg';
import { ReactComponent as ShopIcon } from '../../../../images/solar_shop-bold.svg';
import { ReactComponent as SearchIcon } from '../../../../images/search.svg';
import { ReactComponent as Clock } from '../../../../images/clock.svg';
import dayOfWeek from "../../../../utils/dateUtils";

import Logo from "../../../../images/coop-puglia.png";

import HeaderItem from "../HeaderItems/HeaderItem/HeaderItem";
import HeaderLink from "../HeaderItems/HeaderLink/HeaderLink";
import HeaderCategoriesItem from "../HeaderItems/HeaderCategoriesItem/HeaderCategoriesItem";

import "./mobile-header.styles.scss";
import BurgerMenu from "./BurgerMenu/BurgerMenu";
import { connect } from "react-redux";

class MobileHeader extends React.Component {

    updateCart = () => {
        if (this.props.user && config.SHOW_CART_UPDATE) {
            return (
                <Link className="pin-link" to="/cart/update">
                    <nobr>
                        <span>{this.props.cart && this.props.cart.storeDescription ?
                            this.props.cart.storeDescription : config.DEFAULT_STORE_DESCRIPTION}</span>
                    </nobr>
                </Link>
            );
        } else {
            return (
                <nobr>
                    <span>{this.props.cart && this.props.cart.storeDescription ?
                        this.props.cart.storeDescription : config.DEFAULT_STORE_DESCRIPTION}</span>
                </nobr>
            );
        }
    }

    collapseTree = () => {
        this.props.changeCategory("", 0);
    }

    render() {
        const { user, cart, searchInput, onChange, closeAll, availability } = this.props;
        const availableDay = availability && dayOfWeek(availability.weekDay)
        return (
            <Translation>
                {t =>
                    <div className="mobile-header">
                        <div className="mobile-header-wrapper">
                            <div className="container-fluid">
                                <div className="row mobile-header-bottom">
                                    <div className="logo-container">
                                        <BurgerMenu />
                                        <div className="logo align-items-center">
                                            <Link to="/" onClick={() => { closeAll(); this.collapseTree() }}>
                                                <img src={Logo} alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        {user ?
                                            <Link style={{ color: "black" }} to="/profile">
                                                <HeaderItem
                                                    icon="user"
                                                    label={user ? user.profile ? user.profile.firstName : user.firstName : "access"}
                                                    alwaysActived={false}
                                                    actived={false}
                                                />
                                            </Link>
                                            :
                                            <HeaderItem
                                                icon="user"
                                                label={"access"}
                                                alwaysActived={false}
                                                onClick={this.props.loginModal}
                                                actived={this.props.showLogin}
                                            />
                                        }
                                        <Link to="/stores" style={{ color: "black" }}>
                                            <HeaderItem
                                                icon="shop"
                                                label={"stores"}
                                                alwaysActived={false}
                                                actived={false}
                                            />
                                        </Link>
                                        {user &&
                                            <HeaderLink
                                                icon="shopping-cart"
                                                path="/cart"
                                                footer={this.props.renderTotal(cart)}
                                                items={cart ? cart.itemsCount : "0"}
                                                closeAll={this.props.closeAll}
                                            />
                                        }
                                        {!user &&
                                            <HeaderItem
                                                icon="shopping-cart"
                                                label="cart"
                                                alwaysActived={false}
                                                onClick={this.props.loginModal}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-header-search">
                                <form onSubmit={e => {
                                    e.preventDefault();
                                    this.props.history.push({
                                        pathname: "/search",
                                        state: { searchInput: searchInput }
                                    })
                                    closeAll();
                                }}>
                                    <input type="text" onChange={onChange} value={searchInput}
                                        placeholder="Cerca un prodotto"></input>
                                    <button type="submit" className="outline">
                                        <SearchIcon />
                                    </button>
                                </form>
                            </div>
                            <div className="mobile-header-top">
                                <div className="icon-box">
                                    <ShopIcon />
                                </div>
                                <div className="description-container">
                                    <PinIcon className="pin" />
                                    <span className="description">Modalità di consegna:&nbsp;</span>
                                    {this.updateCart()}
                                </div>
                                <div className="description-container">
                                    {cart && availability &&
                                        <div>
                                            <Clock style={{ marginRight: "10px", marginBottom: "6.6px" }} />
                                            <span className="description">Prima disponibilità:</span>
                                            <span className="mr-2"> {t(`daysOfWeek.${availableDay.name}`)}</span>
                                            <span>{availableDay.dayNumber} {t(`months.${availableDay.month}`)}</span>
                                            <span> ({availability.validFrom} - {availability.validTo})</span>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                }
            </Translation>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeCategory: (categoryId, index) => dispatch(actionCreators.changeCategory(categoryId, index))
    };
}

export default withRouter(connect(null, mapDispatchToProps)(MobileHeader))