import React, { useEffect, useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import { useTranslation } from "react-i18next";
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions"

import "./category-tree.styles.scss";
import { isMobile } from "react-device-detect";

const CategoryTree = ({
    categories,
    activeKey,
    mainCategory,
    subCategory,
    isSubCategoryOpen,
    thirdLevelCategory,
    changeCategory,
    changeSubCategory,
    changeThirdLevelCategory,
    handleClose
}) => {
    const { t } = useTranslation();
    const [showCategories, setShowCategories] = useState(true);
    let refItem = {};
    let oldCategory = null;

    const handleScroll = () => {
        if(mainCategory !== "01") {
            refItem[mainCategory].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }

    useEffect(() => {
        if (mainCategory) {
            // handleScroll();
            if (oldCategory !== mainCategory) {
                setTimeout(() => {
                    handleScroll();
                }, 300)
            } else {
                handleScroll();
            }
        }
        oldCategory = mainCategory;
    }, [mainCategory])

    return (
        <div className="category-tree">
            <div className="view_all" onClick={() => setShowCategories(!showCategories)}>
            </div>
            <Accordion className="category-subcategory" defaultActiveKey={activeKey}>
                <ul>
                    {categories && categories.map((item, index) => {
                        if (item.id === mainCategory || showCategories) {
                            return (
                                <div key={item.id} ref={el => refItem[item.id] = el}>
                                    <div className="category-sidebar" key={item.id}>
                                        <Accordion.Toggle as="li" eventKey={item.id}
                                            onClick={() => { changeCategory(item.id, index) }}
                                        >
                                            <span className={`category-sidebar-option 
                                                ${item.id === mainCategory ? "category-sidebar-option-selected" : ""}`}>
                                                {item.description}
                                            </span>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse
                                            eventKey={item.id}
                                        >

                                            <nav className={`category-sidebar-sub ${item.items ? "" : "d-none"}`}>
                                                {item.items && item.items.map((subcategory, index) => (
                                                    <React.Fragment key={subcategory.id}>
                                                        <li
                                                            onClick={() => changeSubCategory(subcategory.id, item.description, subcategory.description)}
                                                        >
                                                            <span className={`category-sidebar-sub-option 
                                                                            ${subcategory.id === subCategory ? "category-sidebar-sub-option-selected" : ""}`}>
                                                                {subcategory.description}
                                                            </span>
                                                        </li>
                                                        {subCategory !== "" && isSubCategoryOpen &&
                                                            <nav className="third-level">
                                                                {subcategory && subcategory.items && subcategory.items.map((thirdCategory, index) => {
                                                                    if (subcategory.id === subCategory) {
                                                                        return (
                                                                            <li key={thirdCategory.id}
                                                                                onClick={() => {
                                                                                    changeThirdLevelCategory(thirdCategory.id, item.description, subcategory.description, thirdCategory.description); 
                                                                                    if (isMobile) handleClose()
                                                                                }}
                                                                            >
                                                                                <span className={`category-sidebar-sub-sub-option 
                                                                                            ${thirdCategory.id === thirdLevelCategory ? "category-sidebar-sub-sub-option-selected" : ""}`}>
                                                                                    {thirdCategory.description}
                                                                                </span>
                                                                            </li>
                                                                        )
                                                                    } else return null;
                                                                })}
                                                            </nav>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </nav>

                                        </Accordion.Collapse>
                                    </div>
                                    {categories.length !== index + 1 &&
                                        <hr />
                                    }
                                </div>
                            );
                        } else return null;
                    })}
                </ul>
            </Accordion>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        categories: state.cart.categories,
        activeKey: state.categories.activeKey,
        mainCategory: state.categories.mainCategory,
        subCategory: state.categories.subCategory,
        isSubCategoryOpen: state.categories.isSubCategoryOpen,
        thirdLevelCategory: state.categories.thirdLevelCategory,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        changeCategory: (categoryId, index) => dispatch(actionCreators.changeCategory(categoryId, index)),
        changeSubCategory: (categoryId, firstLevelCategory, categoryDescription) => dispatch(actionCreators.changeSubCategory(categoryId, firstLevelCategory, categoryDescription)),
        changeThirdLevelCategory: (categoryId, firstLevelCategory, categoryDescription, thirdLevelCategory) => dispatch(actionCreators.changeThirdLevelCategory(categoryId, firstLevelCategory, categoryDescription, thirdLevelCategory)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTree);