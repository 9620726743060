import React, { useState } from 'react';
import "./scrollToTop.styles.scss";
import { ReactComponent as Up } from "../../images/go-up.svg";
import i18n from '../../i18n';

const ScrollToTopButton = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener("scroll", toggleVisible);
    return (
        <div style={{ display: visible ? "flex" : "none"}} className="scrollDiv">
            <button className="goUp" onClick={scrollToTop}>{i18n.t('goUp')}<Up style={{margin: "5px"}}/></button>
        </div>
    )
}

export default ScrollToTopButton