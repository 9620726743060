import store from "../redux/store";

export function authHeader(jwt) {
    //jwt from the webview
    if (jwt){
        return { 'Authorization': 'Bearer ' + jwt };
    } else {
        // return authorization header with jwt token
        let user = store.reduxStore.getState().user;
        if (user && user.jwt) {
            return { 'Authorization': 'Bearer ' + user.jwt };
        } else {
            return {};
        }
    }
}