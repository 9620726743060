import React from 'react'
import './faq.styles.scss'

const Faq = () => {
    return (
        <div className='faq'>
            <label className='faq-title'>Domande Frequenti</label>
            <label className='faq-subtitle'>COME FARE I TUOI ACQUISTI</label>


            <label className='faq-question'>In cosa consiste il servizio e come posso accedervi?</label>
            <div className='faq-answer'>
                <label>Coop Master Spesa On Line è il servizio di spesa on line dei supermercati Coop Master di Puglia e Basilicata.</label>
                <label>L’utente potrà effettuare un ordine on line scorrendo l’assortimento presente e decidere se ritirare personalmente la spesa in uno dei punti vendita disponibili o farsela consegnare direttamente al proprio domicilio (casa, ufficio, ecc.). Il servizio di consegna a domicilio è attualmente attivo nei comuni di Bari, Barletta, Bitonto, Bisceglie, Giovinazzo, Palo del Colle, Taranto e Trani.</label>
                <label>L’ordine può essere effettuato</label>
                <ol>
                    <li>da Smartphone o Tablet tramite l’App, Coop Master Puglia Spesa Online (Ios e Android)</li>
                    <li>da PC sul sito Coopmasterpuglia.it</li>
                    <li>Telefonicamente chiamando l’help desk al numero <a href='tel:+39 3456030207'>3456030207</a>, un operatore provvederà a prendere in carico l’ordine del cliente. L’help desk è attivo esclusivamente per le prenotazioni delle consegne a domicilio. Non è utilizzabile per prenotare il ritiro spesa presso il punto vendita.</li>
                </ol>
            </div>
            <label className='faq-question'>C'è un limite minimo di spesa per usufruire del servizio di consegna a casa e/o di ritiro in punto vendita?</label>
            <div className='faq-answer'>
                <label>Per usufruire del servizio il limite minimo di spesa è di 25,00 Euro. In determinati periodi potrebbero essere comunicati importi differenti.</label>
            </div>
            <label className='faq-question'>Quali sono i giorni e le fasce orarie in cui è possibile usufruire del servizio di consegna a casa e di ritiro in punto vendita?</label>
            <div className='faq-answer'>
                <label>Il Servizio è attivo dal Lunedì al Sabato, esclusi i festivi. Puoi verificare e scegliere le fasce orarie per ricevere la tua spesa a casa durante l’ordine on line.</label>
                <label>Prima di iniziare sarà comunque visibile la prima fascia oraria di consegna. Prima di chiudere l’ordine potrai comunque impostare il giorno e l’ora da te desiderato per il ritiro o la consegna sulla base dei tuoi impegni e delle tue esigenze.</label>
            </div>
            <label className='faq-question'>Dove è attivo il servizio?</label>
            <div className='faq-answer'>
                <label>Oggi il servizio di consegna a domicilio è attivo nei comuni di Bari, Barletta, Bitonto, Bisceglie, Giovinazzo, Palo del Colle, Taranto e Trani mentre il servizio di ritiro della spesa pronta è attivo in 11 punti vendita nei comuni di Bari, Barletta, Bitonto, Trani e Taranto. Potrai selezionare il tuo punto vendita in cui effettuerai il ritiro all’inizio della spesa on line.</label>
            </div>
            <label className='faq-question'>Da dove arriva la mia spesa?</label>
            <div className='faq-answer'>
                <label>I domicili vengono preparati nei seguenti supermercati:</label>
                <label>Bari, Coop Superstore di Via Papalia a Bari</label>
                <label>Barletta, Coop di Via Sant’Antonio a Barletta</label>
                <label>Bitonto, Giovinazzo, Palo del Colle, Bari S. Spirito, Bari Palese, Bari S. Paolo, Coop di Via Della Repubblica a Bitonto</label>
                <label>Trani e Bisceglie, Coop di via Tolomeo a Trani</label>
                <label>Taranto, Coop di Via Umbria</label>
                <label>In tutte le Coop il personale addetto è tenuto a rispettare i più elevati standard di sicurezza in tutte le fasi di preparazione: approvvigionamento e stoccaggio (grazie ad ambienti e celle a diverse temperature) e consegna (attraverso una flotta di furgoni refrigerati).</label>
            </div>


            <label className='faq-subtitle'>LA PREPARAZIONE DELLA SPESA</label>
            <label className='faq-question'>Come funziona la preparazione della spesa?</label>
            <div className='faq-answer'>
                <label>Il giorno di consegna un nostro operatore specializzato si prenderà cura dei prodotti da te scelti occupandosi personalmente della selezione dei prodotti e della predisposizione nelle casse di consegna.</label>
            </div>
            <label className='faq-question'>Su Coop on Line trovo tutte le promozioni che trovo sul volantino cartaceo?</label>
            <div className='faq-answer'>
                <label>Sì, nel caso tu richieda il servizio di consegna a domicilio trovi tutte le promo del volantino Coop in vigore nel punto vendita di preparazione. Nel caso tu richieda il servizio di ritiro in punto vendita troverai le offerte attive nel punto vendita da te selezionato per il ritiro. Attenzione: i prezzi in offerta vengono applicati solo se la data di consegna richiesta ricade nel periodo di validità dell’offerta, altrimenti i prezzo di vendita sarà quello in vigore in cassa al momento dell’emissione dello scontrino.</label>
            </div>
            <label className='faq-question'>Come posso annullare la mia spesa?</label>
            <div className='faq-answer'>
                <label>Puoi annullare il tuo ordine fino a quando non viene preso in carico dai nostri preparatori. In caso di impossibilità a ricevere la consegna presso il domicilio indicato ti preghiamo di contattare l’Assistenza Clienti.</label>
            </div>
            <label className='faq-question'>Come faccio a capire se la mia spesa è confermata?</label>
            <div className='faq-answer'>
                <label>Per essere sicuro del completamento della procedura d’acquisto, devi arrivare fino alla fase di conferma e pagamento dell'ordine. Inoltre, riceverai una conferma via e-mail con il numero di riferimento del tuo ordine e il riepilogo di tutti i prodotti acquistati, della modalità e dell'indirizzo di spedizione. Potrai inoltre verificare lo stato del tuo ordine nell’area personale.</label>
            </div>


            <label className='faq-subtitle'>PAGAMENTI</label>
            <label className='faq-question'>Quali sono le modalità di pagamento?</label>
            <div className='faq-answer'>
                <label>Una volta completata la spesa on line ed inseriti i tuoi dati potrai decidere di pagare online tramite carta di credito o direttamente alla consegna tramite Bancomat, Carta di Credito o in contanti. <b style={{fontFamily: 'FuturaStd-Bold'}}>NB Nel caso di pagamento anticipato con carta di credito effettueremo una richiesta di pre-autorizzazione per un importo superiore del 20% al valore del carrello prenotato. Il giorno della consegna al momento dell’emissione dello scontrino verrà scalato l’effettivo importo della spesa.</b> Nel caso decidiate di ritirare la vostra spesa on line direttamente in uno dei negozi attivi al ritiro potrete decidere di pagare la spesa al ritiro anche in contanti.</label>
            </div>


            <label className='faq-subtitle'>CONSEGNA</label>
            <label className='faq-question'>Quanto costa la consegna?</label>
            <div className='faq-answer'>
                <label>Il costo della consegna è di 3,50€ ma è possibile che, in determinati momenti, venga comunicato un costo differente o la gratuità del servizio. Il costo comprende la preparazione della spesa da parte dei nostri professionisti, il trasporto in appositi furgoni a temperatura controllata e la consegna al piano nella fascia oraria richiesta della spesa e di un numero massimo di 2 fardelli d’acqua (12 bottiglie).</label>
            </div>
            <label className='faq-question'>Esistono limiti massimi di acquisto?</label>
            <div className='faq-answer'>
                <label>E’ possibile acquistare fino ad un massimo di 30 pezzi di ogni singolo articolo. <b style={{fontFamily: 'FuturaStd-Bold'}}>Relativamente all’acqua in bottiglia, è previsto un limite massimo di 30 bottiglie (5 fardelli) per ordine.</b> Il costo di trasporto delle prime 12 bottiglie (2 fardelli) è compreso nel costo del servizio, mentre verrà applicato un sovraccarico di 0,50€ per ogni fardello eccedente al secondo. Ricordati che sono il numero di bottiglie e non il peso, a concorrere al raggiungimento della soglia e che comunque il numero massimo di fardelli d’acqua acquistabile è cinque.</label>
            </div>
            <label className='faq-question'>La spesa verrà portata fino al piano del mio appartamento?</label>
            <div className='faq-answer'>
                <label>Sì, la spesa verrà portata fino al piano del tuo appartamento. Al momento della consegna il nostro corriere ti chiederà di firmare la bolla di consegna per verificare l’avvenuta ricezione della spesa. Ti ricordiamo che i nostri spedizionieri non rispondono direttamente della merce consegnata. Per qualsiasi reclamo ti chiediamo di contattare l’Assistenza Clienti</label>
            </div>
            <label className='faq-question'>Cosa succede nel caso in cui alcuni prodotti ordinati non siano disponibili al momento della preparazione dell'ordine?</label>
            <div className='faq-answer'>
                <label>Ci assicuriamo che i prodotti siano sempre pronti e disponibili nel giorno della consegna. Nel caso alcuni prodotti non siano disponibili, i prodotti inevasi verranno decurtati dal totale della spesa ed evidenziati in fondo al documento di consegna. Cercheremo comunque di contattarti per proporti prodotti alternativi a quelli indisponibili da te richiesti per garantirti una spesa completa.</label>
            </div>
            <label className='faq-question'>Cosa succede se al momento della consegna non sono in casa?</label>
            <div className='faq-answer'>
                <label>Per la consegna, all'indirizzo e all'orario indicato, è necessaria la tua presenza o di un tuo incaricato. Ti invieremo una mail per ricordarti del tuo ordine e la fascia oraria da te scelta. Qualora il corriere non trovasse te o un tuo incaricato presso l’indirizzo di consegna provvederemo a chiamarti/ inviarti un SMS. Nel caso nessuno fosse presente, l'ordine si intenderà annullato ed il costo della consegna e dei prodotti non più vendibili (ad es. prodotti alimentari freschi e deperibili) ti sarà addebitato.</label>
            </div>


            <label className='faq-subtitle'>ASSORTIMENTO</label>
            <label className='faq-question'>Sono disponibili a catalogo prodotti senza glutine?</label>
            <div className='faq-answer'>
                <label>Nel catalogo prodotti sono disponibili molti prodotti senza glutine.</label>
            </div>
            <label className='faq-question'>È possibile visualizzare informazioni specifiche sui prodotti a seconda del mio stile di vita?</label>
            <div className='faq-answer'>
                <label>Sì, all’interno delle schede prodotto sono presenti le informazioni di dettaglio sui prodotti.</label>
                <label>In queste pagine potrai verificare che gli alimenti selezionati siano compatibili con le tue preferenze e il tuo stile di vita.</label>
            </div>
            <label className='faq-question'>Posso salvare una lista di prodotti preferiti?</label>
            <div className='faq-answer'>
                <label>Si potrai salvare liste di preferiti e clonare spese precedentemente salvate.</label>
            </div>


            <label className='faq-subtitle'>DATI PERSONALI</label>
            <label className='faq-question'>Cos'è la tua area personale?</label>
            <div className='faq-answer'>
                <label>L'area personale è uno spazio dedicato ai clienti, all’interno della quale è possibile gestire i dati personali e gli indirizzi di consegna, visualizzare lo storico degli ordini e le liste personalizzate.</label>
            </div>
            <label className='faq-question'>Come è protetta la privacy?</label>
            <div className='faq-answer'>
                <label>Garantiamo la massima riservatezza sulle informazioni rilasciate dal cliente al momento della registrazione. Tutte le informazioni sono trasmesse in una connessione protetta, in modo da impedire a terzi di intercettarle. Le informazioni facoltative che richiediamo all'atto della registrazione sono utilizzate per migliorare il nostro servizio.</label>
            </div>
            <label className='faq-question'>Ho cambiato l'e-mail, cosa devo fare?</label>
            <div className='faq-answer'>
                <label>Se hai cambiato e-mail personale dovrai aggiornarla nella tua area personale. Dopo aver effettuato il login con il vecchio indirizzo e-mail, potrai aggiornare la tua e-mail nella sezione "Profilo".</label>
            </div>
            <label className='faq-question'>Cosa posso fare se dimentico la password?</label>
            <div className='faq-answer'>
                <label>Se hai dimenticato la tua password per accedere all’area personale, clicca sul pulsante “Hai dimenticato la password?” durante la fase di login alla tua area personale. Ti sarà richiesto di inserire il tuo indirizzo email col quale ti sei registrato e ti invieremo una email contenente un link che ti porterà alla pagina dedicata alla sostituzione della password.</label>
            </div>
        </div>
    )
}

export default Faq