import config from "../../../../config/config"
import { handleResponse } from "../../../../utils/handleResponse";
import { checkResponse } from "../../../../utils/checkResponse";
import axios from "axios"
import { authHeader } from "../../../../utils/authHeader";
import { handleError } from "../../../../utils/handleCatch";

export default function disponibility(cartId) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/slots/${cartId}/days`, {
            headers: authHeader()
        })
        .then(handleResponse)
        .then(checkResponse)
            
    );
}