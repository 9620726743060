import React from "react"
import Placeholder from "../../../images/product-placeholder.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import config from "../../../config/config"
import { ReactComponent as SlistIcon } from "../../../images/slist.svg"
import { ReactComponent as IsInSlistIcon } from "../../../images/list.svg"
import { ReactComponent as Frozen } from "../../../images/frozen.svg"
import { history } from "../../../App";
import { format, parseISO } from "date-fns";
import CounterInput from "../../../components/UI/Counter/CounterInput"
import "./card-product.styles.scss";
import ReactTooltip from 'react-tooltip'
import Modal from "react-bootstrap/Modal"
import toaster from "../../../utils/toaster"

class CardProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: "",
            isAlreadyInList: false,
            showPopup: false,
            open: false,
            showButtons: false,
            product: null,
            unity: null,
            incremented: false,
            decremented: false,
        }
    }

    componentDidMount() {
        this.setCartAmount();
        this.setIsInList();
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentSlist } = this.props;
        if (prevProps.currentSlist?.items?.length !== currentSlist?.items?.length) {
            this.setIsInList();
        }
    }

    setCartAmount = () => {
        const { cart, item } = this.props;
        var isAlreadyBooked = false;
        var itemAlreadyBooked = null;
        var replacement = false;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === item.ref) {
                    isAlreadyBooked = true;
                    itemAlreadyBooked = element;
                }
            });
        }
        if (isAlreadyBooked) {
            if (itemAlreadyBooked.purchaseUm === "PZ") {
                this.setState({ amount: itemAlreadyBooked.amount })
            } else {
                this.setState({ amount: parseFloat(itemAlreadyBooked.weight?.toFixed(2)) })
                if (itemAlreadyBooked.attrib3) {
                    if (itemAlreadyBooked.umStep < itemAlreadyBooked.weight) {
                        let steps = Math.ceil(itemAlreadyBooked.weight / itemAlreadyBooked.umStep);
                        let newUnity = parseInt(itemAlreadyBooked.attrib3) * steps;
                        this.setState({ unity: newUnity });
                    } else {
                        this.setState({ unity: parseInt(itemAlreadyBooked.attrib3) });
                    }
                }
            }
        } else {
            this.setState({ amount: item?.minWeight ? parseFloat(item.minWeight?.toFixed(2)) : parseFloat(item?.umStep?.toFixed(2)) })
            if (item.attrib3) {
                this.setState({ unity: parseInt(item.attrib3) })
            }
        }
    }

    setIsInList = () => {
        const { currentSlist, item } = this.props;
        var isAlreadyInList = false;
        if (currentSlist && currentSlist.items) {
            currentSlist.items.forEach(element => {
                if (element.ref === item.ref) {
                    isAlreadyInList = true;
                }
            });
        }
        this.setState({ isAlreadyInList: isAlreadyInList })
        return isAlreadyInList
    }

    checkIsBooked = () => {
        const { cart, item } = this.props;
        var isAlreadyBooked = false;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === item.ref) {
                    isAlreadyBooked = true;
                }
            });
        }
        return isAlreadyBooked;
    }


    renderPrices = (item) => {
        if (item.price !== item.netPrice) {
            return (
                <nobr>
                    <span className="text-strike strike">
                        {`${item.price.toFixed(2)} \u20AC`}
                    </span>
                </nobr>
            );
        }
    }
    renderPricePerUm = (item) => {
        return (
            <nobr>
                <span className="pricePerUM">
                    {`${item.pricePerUM?.toFixed(2)} \u20AC / ${item.umPerUM}`}
                </span>
            </nobr>
        );

    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                return (
                    <div className="promo-container">
                        <div className="promo-title">-{item.promotions[0].value}%</div>
                        <div className="promo-valid">
                            Valida fino al {format(parseISO(item.promotions[0].validTo), "dd/MM")}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div style={{ lineHeight: "24px" }}>
                        <div>&nbsp;</div>
                    </div>
                );
            }
        }
    }


    isAlreadyBooked = (item) => {
        var isAlreadyBooked = false;
        var itemToAdd = null;
        if (this.props.cart.items) {
            const items = this.props.cart.items;
            for (var i = 0; i < items.length; i++) {
                if (items[i].ref === item.ref) {
                    isAlreadyBooked = true;
                    itemToAdd = items[i];
                    break;
                }
            }
        } else {
            if (item.purchaseUm === "KG") {
                history.push({
                    pathname: `/product/${item.barcode}`,
                    state: { product: item }
                })
            } else {
                this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
            }
            return;
        }

        if (isAlreadyBooked) {
            const addItem = { ...itemToAdd, available: true }
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: addItem, isDetail: true }
            })
        } else {
            if (item.purchaseUm === "KG") {
                history.push({
                    pathname: `/product/${item.barcode}`,
                    state: { product: item }
                })
            } else {
                this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
            }
        }
    }

    goToDetails = (item) => {
        var isAlreadyBooked = false;
        var itemToAdd = null;
        const { cart, from, category, mainCategory, categoryTitle, subCategory, activeKey } = this.props;
        if (cart && cart.items) {
            const items = cart.items;
            for (var i = 0; i < items.length; i++) {
                if (items[i].ref === item.ref) {
                    isAlreadyBooked = true;
                    itemToAdd = items[i];
                    break;
                }
            }
        } else {
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: item, from, category, mainCategory, categoryTitle, subCategory, activeKey }
            })
            return;
        }

        if (isAlreadyBooked) {
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: itemToAdd, isDetail: true, from, category, mainCategory, categoryTitle, subCategory, activeKey }
            })
        } else {
            history.push({
                pathname: `/product/${item.barcode}`,
                state: { product: item, from, category, mainCategory, categoryTitle, subCategory, activeKey }
            })
        }
    }

    incrementAmount = () => {
        const { item, cart } = this.props;
        const { amount } = this.state;
        let roundStep = parseFloat(item.umStep?.toFixed(2));
        let newAmount = +amount + roundStep;
        newAmount = newAmount?.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (item?.maxAmount) {
            if (newAmount <= item.maxAmount) {
                this.setState({ amount: newAmount })
            }
        } else {
            this.setState({ amount: newAmount })
        }
        if (item.attrib3) {
            let newUnity = this.state.unity + parseInt(item.attrib3);
            this.setState({ unity: newUnity });
        }
        if (this.checkIsBooked()) {
            const cartItem = cart.items.find(element => element.ref === item.ref);
            if (newAmount !== cartItem.amount && !this.state.incremented) {
                this.setState({ incremented: true });
                toaster.standard("Dopo aver modificato la quantità aggiornare il carrello");
            }
        }
    }

    decrementAmount = () => {
        const { item, cart } = this.props;
        const { amount } = this.state;
        let roundStep = parseFloat(item.umStep?.toFixed(2));
        let newAmount = +amount - roundStep;
        newAmount = newAmount?.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (item.minWeight) {
            if (amount > item.minWeight) {
                this.setState({ amount: newAmount })
            }
        } else if (amount > parseFloat(item.umStep?.toFixed(2))) {
            this.setState({ amount: newAmount })
        }
        if (item.attrib3 && this.state.unity > item.attrib3) {
            let newUnity = this.state.unity - item.attrib3;
            this.setState({ unity: newUnity });
        }
        if (this.checkIsBooked()) {
            const cartItem = cart.items.find(element => element.ref === item.ref);
            if (newAmount !== cartItem.amount && !this.state.decremented) {
                this.setState({ decremented: true });
                toaster.standard("Dopo aver modificato la quantità aggiornare il carrello");
            }
        }
    }

    render() {
        const { item, user, currentSlist, cart, setProductToAdd, slists, remove, store } = this.props;
        const { isAlreadyInList, open } = this.state;
        const isPromo = item.promotions && item.promotions[0] !== null && item.promotions[0] !== undefined
        return (
            <div className="card-product" style={{ ...this.props.style }}>
                <Modal show={open} onHide={() => this.setState({ open: false })}>
                    <div className="slist-modal">
                        <Modal.Header closeButton>
                            <label className="title">Aggiungi prodotto alla lista</label>
                        </Modal.Header>
                        <Modal.Body>
                            <label className="subtitle">Seleziona la lista a cui vuoi aggiungere il prodotto</label>
                            <div className="slists">
                                {slists && slists.map(list => (
                                    <div onClick={() => { this.props.itemCreate(currentSlist.id, item.barcode, this.state.amount); this.setState({ open: false }) }} key={list.id}>
                                        {list.title}
                                    </div>
                                ))}
                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
                {config.ENABLED_SLIST &&
                    <div className="card-product-slist">
                        {user &&
                            <button className="slist outline"
                                onClick={() => currentSlist && isAlreadyInList ? this.props.itemRemove(currentSlist.id, item.id) : this.setState({ open: true })}
                                style={isAlreadyInList ? { backgroundColor: "#59B031", borderRadius: '8px' } : null}>
                                {isAlreadyInList ?
                                    <IsInSlistIcon />
                                    :
                                    <SlistIcon />
                                }
                            </button>
                        }
                        {!user &&
                            <button className="slist outline"
                                onClick={() => { document.getElementById('user').click(); }}>
                                <SlistIcon />
                            </button>
                        }
                    </div>
                }
                <div className={"card-product-container"}>
                    {item?.categoryId?.startsWith("04") &&
                        <>
                            <div className="frozen" onClick={() => this.goToDetails(item)}>
                                <Frozen data-tip data-for="registerTip" />
                            </div>
                            <ReactTooltip id="registerTip" place="top" effect="solid" backgroundColor="#F5F5F5" textColor="black">
                                Prodotto Surgelato
                            </ReactTooltip>
                        </>
                    }
                    <div className={"card-product-image"}>
                        <div className="link" onClick={() => this.goToDetails(item)}>
                            <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                onError={(e) => { e.target.src = Placeholder }}
                            />
                        </div>
                    </div>
                    {/* <div className={"card-product-promo"}>
                        {this.renderPromo(item)}
                    </div> */}
                </div>
                <div className="card-product-details">
                    <div className="link" onClick={() => this.goToDetails(item)}>
                        <span>{item.description}</span>
                        <span className="brand">{item.brand}</span>
                    </div>
                </div>
                {/* <div className="card-product-um">
                    {item.pricePerUm && item.umPerUm &&
                        `${item.pricePerUm.toFixed(2)} \u20AC / ${item.umPerUm} `
                    }
                </div> */}
                <div className="card-product-promo-puglia">
                    {this.renderPromo(item)}
                </div>
                <div className="card-product-prices">
                    {/* {this.renderPricePerUm(item)} */}
                    <span></span>
                    {this.renderPrices(item)}
                    <nobr><span className="netTotal" style={isPromo ? { color: '#E2001A' } : {}}>{`${item.netPrice.toFixed(2)} \u20AC`}</span></nobr>
                </div>

                <div className="card-product-buttons row">
                    <CounterInput
                        value={this.state.amount}
                        item={item}
                        remove={() => remove(cart.id, item.barcode)}
                        increment={this.incrementAmount}
                        decrement={this.decrementAmount}
                        attrib3={item.attrib3}
                        checkIsBooked={this.checkIsBooked}
                    />
                    <div>
                        {user && cart &&
                            <button style={this.checkIsBooked() ? !this.state.incremented && !this.state.incremented ? isBookedStyle : buttonStyle : buttonStyle} className="cart outline"
                                onClick={() => {
                                    store(cart.id, item.barcode, this.state.amount, item.um);
                                    this.setState({
                                        incremented: false,
                                        decremented: false
                                    })
                                }}>
                                {/* // onClick={() => cart && this.isAlreadyBooked(item)}> */}
                                <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                            </button>
                        }
                        {user && !cart &&
                            <button style={this.checkIsBooked() ? isBookedStyle : buttonStyle} className="cart outline"
                                onClick={() => {
                                    setProductToAdd(item, this.state.amount);
                                    document.getElementById('shopping-cart').click();
                                }}>
                                <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                            </button>
                        }
                        {!user &&
                            <button style={this.checkIsBooked() ? isBookedStyle : buttonStyle} className="cart outline"
                                onClick={() => {
                                    setProductToAdd(item, this.state.amount);
                                    document.getElementById('user').click();
                                }}>
                                <FontAwesomeIcon icon="shopping-cart" style={{ width: "18px", height: "auto" }} />
                            </button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const buttonStyle = {
    width: config.ENABLED_SLIST ? "" : "100%",
    marginLeft: config.ENABLED_SLIST ? "" : "unset",
}
const isBookedStyle = {
    width: config.ENABLED_SLIST ? "" : "100%",
    marginLeft: config.ENABLED_SLIST ? "" : "unset",
    backgroundColor: "#59B031",
    border: "0"
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        currentSlist: state.slists.currentSlist,
        slists: state.slists.slists,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setProductToAdd: (item, amount) => dispatch(actionCreators.setProductToAdd(item, amount)),
        store: (cartId, barcode, amount, um) => dispatch(actionCreators.store(cartId, barcode, amount, um)),
        remove: (cartId, barcode) => dispatch(actionCreators.remove(cartId, barcode)),
        itemCreate: (listId, barcode, amount) => dispatch(actionCreators.itemCreate(listId, barcode, amount)),
        itemRemove: (listId, productId) => dispatch(actionCreators.itemDelete(listId, productId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardProduct);