import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { ecommerceService } from '../../../services/ecommerce/EcommerceService';
import './filter.styles.scss'

const Filter = ({ total, onChange, onlyPromotions, onlyBranded, tag, filter, deleteFilter }) => {
    const { t } = useTranslation();
    const [tags, setTags] = useState([])

    useEffect(() => {
        ecommerceService.getTags()
            .then(response => {
                console.log(response);
                setTags(response)
            })
            .catch(error => {
                console.log(error);
            })
    }, [])

    return (
        <div className='filter'>
            <label className='total'>{total} risultati</label>
            <div className='checkbox'>
                <label>Filtra per:</label>
                <div>
                    <div className='checkbox-container'>
                        <input type="checkbox"
                            value={onlyPromotions}
                            id="onlyPromotions" name="onlyPromotions"
                            onChange={onChange}
                        />
                        <label htmlFor="onlyPromotions">
                            {t("onlyPromotions")}
                        </label>
                    </div>
                    <div className='checkbox-container'>
                        <input type="checkbox"
                            value={onlyBranded}
                            id="onlyBranded" name="onlyBranded"
                            onChange={onChange}
                        />
                        <label htmlFor="onlyBranded">
                            {t("onlyBranded")}
                        </label>
                    </div>
                </div>
            </div>
            <div className='select'>
                <select onChange={onChange} id="tag" name="tag" value={tag}>
                    <option value="" disabled selected hidden>Seleziona tag</option>
                    {tags.length > 0 && tags.map(item => (
                        <option value={item.id} key={item.id}>
                            {item?.description}
                        </option>
                    ))}
                </select>
                <select onChange={onChange} id="filter" name="filter" value={filter}>
                    <option value="" disabled selected hidden>Ordina per</option>
                    <option value="price-ASC">
                        Prezzo crescente
                    </option>
                    <option value="price-DESC">
                        Prezzo decrescente
                    </option>
                    <option value="description-ASC">
                        Nome A/Z
                    </option>
                    <option value="description-DESC">
                        Nome Z/A
                    </option>
                </select>
                {(tag !== "" || filter !== "") &&
                    <button onClick={deleteFilter}>
                        Cancella filtri
                    </button>
                }
            </div>
        </div>
    )
}

export default Filter