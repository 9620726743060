import React, { useState } from "react";
import CategoryTree from "../../../../../features/Categories/CategoryTree/CategoryTree";
import { ReactComponent as BurgerIcon } from '../../../../../images/burger-icon.svg';
import { slide as Menu } from 'react-burger-menu'

// import "./burger-menu.styles.scss";

const BurgerMenu = (props) => {
    const [isOpen, setIsOpen] = useState(false)

    const stateChangeHandler = (newState) => {
        setIsOpen(newState.isOpen)
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    return (
        <div className="burger-menu">
            <Menu
                isOpen={isOpen}
                onStateChange={(state) => stateChangeHandler(state)}
                customBurgerIcon={<BurgerIcon />}
                bodyClassName={"no-scroll"}
            >
                {/* <div className="burger-menu-header" onClick={() => setIsOpen(false)} >
                    <button className="secondary-cta" onClick={handleClose}>Conferma</button>
                </div> */}
                <div className="burger-menu-content">
                        <CategoryTree handleClose={handleClose}/>
                </div>
            </Menu>
        </div>
    );
}

export default BurgerMenu;
