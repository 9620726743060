import React, { Component } from 'react';
import { Row, Col, Accordion, Card } from "react-bootstrap"
import OrderCard from '../../components/Cards/OrderCard';
import { ecommerceService } from "../../services/ecommerce/EcommerceService";
import { Translation } from 'react-i18next';
import Order from './Order/Order';
import Pagination from '../../components/UI/Pagination/Pagination';
import toaster from '../../utils/toaster';
import { format, parseISO } from "date-fns";
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"

import "./orders.styles.scss"

class Orders extends Component {

    constructor(props) {
        super(props);

        this.readOrders = (page) => {
            ecommerceService.orders(page)
                .then(response => {
                    if (response.data.length > 0) {
                        ecommerceService.fetchOrder(response.data[0].id)
                            .then(fetchResponse => {
                                if (fetchResponse.data.status === "TO_BE_PREPARED" || fetchResponse.data.status === "IN_PREPARATION" || fetchResponse.data.status === "ABORTED" || fetchResponse.data.status === "PAYMENT_PENDING") {
                                    this.setState({
                                        currentOrder: fetchResponse.data,
                                        items: fetchResponse.data.cart.items,

                                        orders: response.data,
                                        totalPages: response.totalPages,
                                        page: response.number,
                                        isLoaded: true,
                                    })
                                } else {
                                    this.setState({
                                        currentOrder: fetchResponse.data,
                                        items: fetchResponse.data.preparedCartItems,

                                        orders: response.data,
                                        totalPages: response.totalPages,
                                        page: response.number,
                                        isLoaded: true,
                                    })
                                }
                            }).catch((error) => {
                                console.log(error);
                            })
                    } else {
                        this.setState({ isLoaded: true })
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }

        this.state = {
            orders: [],
            currentOrder: null,
            items: [],
            isLoaded: false,
            page: 0,
            totalPages: null
        }
    }

    componentDidMount() {
        this.readOrders(this.state.page);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentOrder !== this.state.currentOrder) {
            ecommerceService.fetchOrder(this.state.currentOrder.id)
                .then(response => {
                    if (this.state.currentOrder.status === "TO_BE_PREPARED" ||
                        this.state.currentOrder.status === "IN_PREPARATION" ||
                        this.state.currentOrder.status === "ABORTED" ||
                        this.state.currentOrder.status === "PAYMENT_PENDING") {
                        this.setState({
                            items: response.data.cart.items
                        })
                    } else {
                        this.setState({
                            items: response.data.preparedCartItems
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
    }

    renderTotal = (item) => {
        let result;
        if (item.operatorCart && item.operatorCart.totalToPay) {
            result = `${item.operatorCart.totalToPay.toFixed(2)} \u20AC`
        } else {
            result = `${item.cart.totalToPay.toFixed(2)} \u20AC`
        }
        return result;
    }

    selectOrder = (orderId, status) => {
        if (orderId !== this.state.currentOrder.id)
            ecommerceService.fetchOrder(orderId)
                .then(response => {
                    if (status === "TO_BE_PREPARED" || status === "IN_PREPARATION" || status === "ABORTED") {
                        this.setState({
                            currentOrder: response.data,
                            items: response.data.cart.items
                        })
                    } else {
                        this.setState({
                            currentOrder: response.data,
                            items: response.data.preparedCartItems
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                })
    }

    abort = (orderId) => {
        ecommerceService.abortOrder(orderId)
            .then(response => {
                this.setState({
                    currentOrder: response.data.order
                })
                this.readOrders(this.state.page);
            }).catch((error) => {
                toaster.error(error.message)
                console.log(error);
            })
    }

    changePage = (page) => {
        if (page >= 0 && page < this.state.totalPages) {
            this.readOrders(page);
        }
    }

    render() {
        const { orders, currentOrder, items, isLoaded, page, totalPages } = this.state;
        if (isLoaded) {
            return (
                <>
                    <Translation>
                        {t =>
                            <>
                                {/* Only on large device */}
                                <div className="container-fluid order-large">
                                    <Row>
                                        <Col sm={12} md={12} lg={12} xl={6}>
                                            <div className="g-padding">
                                                <div className="view-container">
                                                    {orders && orders.map((item) => (
                                                        <Row key={item.id}>
                                                            <OrderCard item={item} currentOrder={currentOrder} selectOrder={this.selectOrder} />
                                                        </Row>
                                                    ))}
                                                    {orders && orders.length === 0 &&
                                                        <span className="title">
                                                            {t("orders.empty")}
                                                        </span>
                                                    }
                                                    <Row className="offset-3">
                                                        <Pagination page={page} totalPages={totalPages} changePage={this.changePage} />
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col style={{ backgroundColor: "#f7f7f7", minHeight: "400px" }} sm={12} md={12} lg={12} xl={6}>
                                            <Order order={currentOrder} items={items} abort={this.abort} copy={this.props.copy} />
                                        </Col>
                                    </Row>
                                </div>
                                {/* Only on small device */}
                                <div className="view-container order-medium">
                                    <Accordion>
                                        {orders && orders.map((item, index) => (
                                            <Card key={item.id} className="mb-4">
                                                <Accordion.Toggle as={Card.Header} className={item.id === currentOrder.id ? "card-selected" : ""}
                                                    eventKey={`${index}`} onClick={() => { this.selectOrder(item.id, item.status) }}>
                                                    <Row>
                                                        <Col sm={2}>
                                                            <h5 className="card-title title">{item.cart.title}</h5>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <h5 className="card-title title">
                                                                {t("order.status")}:
                                                                    <span className={`ml-1 order-status-${item.status}`}>
                                                                    {t(`order.status.${item.status}`)}
                                                                </span>
                                                            </h5>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <p className="card-text subtitle">
                                                                {t("total")}: {this.renderTotal(item)}
                                                            </p>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <p className="card-text-muted">
                                                                {t("deliveryDate")}:    {item.deliveryDate && format(parseISO(item.deliveryDate), "dd/MM/yyyy")}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={`${index}`}>
                                                    <Card.Body>
                                                        <Order order={currentOrder} items={items} abort={this.abort} copy={this.props.copy} />
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                        {orders && orders.length === 0 &&
                                            <span className="title">
                                                {t("orders.empty")}
                                            </span>
                                        }
                                    </Accordion>
                                    <Row className="offset-3">
                                        <Pagination page={page} totalPages={totalPages} changePage={this.changePage} />
                                    </Row>
                                </div>
                            </>
                        }
                    </Translation>
                </>
            );
        } else {
            return null;
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        copy: (orderId) => dispatch(actionCreators.copy(orderId)),
    };
}

export default connect(null, mapDispatchToProps)(Orders);