import config from '../../config/config';
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import { handleError } from "../../utils/handleCatch";
import axios from "axios";
import { authHeader } from '../../utils/authHeader';

export const ecommerceService = {
    deliveryModes, searchProducts, orders, fetchOrder, availability, featuredProducts, abortOrder, getTags, categories
};

function deliveryModes() {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/deliveries`, {
            headers: authHeader(),
        })
            .then(handleResponse)
        // .then(checkResponse)
            
    );
}

function searchProducts(input, storeId, page, category, onlyPromotions, onlyBranded, tag, sort, direction) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/products/?&storeId=${storeId}&q=${input}&p=${page}&category=${category}&onlyPromotions=${onlyPromotions}&onlyBranded=${onlyBranded}&tag=${tag}&sort=${sort}&direction=${direction}`)
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function orders(page) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/orders?s=${config.maxSize}&p=${page}`, {
            headers: authHeader(),
        })
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function fetchOrder(orderId) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/orders/${orderId}/fetch`, {
            headers: authHeader(),
        })
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function availability() {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/slots/first/available`, {
            headers: authHeader(),
        })
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function featuredProducts(storeId) {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/products/${storeId}?onlyPromotions=true`)
            .then(handleResponse)
            .then(checkResponse)
            
    );
}

function abortOrder(orderId) {
    return (
        axios.post(`${config.baseUrl}/cli/ecommerce/cart/abort`,
            {
                orderId: orderId,
            }, {
            headers: authHeader(),
        })
            .then(handleResponse)
            .then(checkResponse)
            
    )
}

function getTags() {
    return (
        axios.get(`${config.baseUrl}/cli/ecommerce/productsTags`)
            .then(handleResponse)
            .then(checkResponse)
            
    )
}

function categories() {
    return (
        axios.get(`${config.baseUrl}/cli/categories`)
            .then(handleResponse)
            .then(checkResponse)
            
    );
}