import config from "../../config/config"
import { handleResponse } from "../../utils/handleResponse";
import { checkResponse } from "../../utils/checkResponse";
import axios from "axios"
import { authHeader } from "../../utils/authHeader";
import { handleError } from "../../utils/handleCatch";

export default function resetPassword(email) {
    return (
        axios.post(`${config.baseUrl}/cli/profiles/password`, {
                tid : "01",
                email: email
            },
            {
                headers: authHeader()
            })
            .then(handleResponse)
            .then(checkResponse)
            
    );
}