import React from "react"
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import * as actionCreators from "../../../redux/store/actions/index"
import { connect } from "react-redux"
import "./profile-menu.styles.scss";

const MobileProfileMenu = ({ history, logout }) => {
    const { t } = useTranslation();
    const handleSelect = (e) => {
        if (e.target.value !== "logout"){
            history.push(e.target.value);
        } else {
            logout()
        }
    }
    const handleClick = (e) => {
        if (e.target.value === "logout") {
            logout()
        }
    }
    return (
        <div className="mobile-profile-menu">
            <select onChange={handleSelect} onClick={handleClick}>
                <option value="/profile">
                    {t("profileContainer.preview")}
                </option>
                <option value="/profile/addresses">
                    {t("profileContainer.shippingAddress")}
                </option>
                <option value="/profile/billing">
                    {t("profileContainer.billingAddress")}
                </option>
                <option value="/profile/password">
                    {t("profileContainer.passwordChange")}
                </option>
                <option value="/profile/orders">
                    {t("profileContainer.orders")}
                </option>
                <option value="/profile/smartlists">
                    {t("profileContainer.slists")}
                </option>
                <option value="/profile/payments">
                    {t("profileContainer.payments")}
                </option>
                <option value="logout">
                    {t("profileContainer.logout")}
                </option>
            </select>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actionCreators.logout()),
    };
}
export default withRouter(connect(null, mapDispatchToProps)(MobileProfileMenu));