const config = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    maxSize: 5,
    DEFAULT_STORE_ID : "1109",
    DEFAULT_STORE_DESCRIPTION : "BARI - COOP SUPERSTORE VIA PAPALIA",
    DEFAULT_STATE: "",
    DEFAULT_STATE_ID: "",
    SHOW_STATE_DROPDOWN : false,
    SHOW_CART_LISTS: true,

    ENABLED_SLIST: true,
    ENABLED_PAYMENTS: false
}

export default config;