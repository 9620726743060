/* eslint-disable */

import * as actionTypes from "../actions/actionTypes";
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    cart: null,
    availability: null,
    productToAdd: null,
    checkoutInfo: null,
    categories: null,
    showFreezePopup: false,
    removedItems: null,
    updatedItems: null
}

const cartReducer = createReducer(initialState, {
    [actionTypes.CART]: (state, action) => { state.cart = action.cart },
    [actionTypes.CATEGORIES]: (state, action) => { state.categories = action.categories },
    [actionTypes.AVAILABILITY]: (state, action) => { state.availability = action.availability },
    [actionTypes.SET_PRODUCT_TO_ADD]: (state, action) => { state.productToAdd = action.productToAdd },
    [actionTypes.ADD_PRODUCT_TO_ADD]: (state, action) => {
        state.cart = action.cart,
        state.productToAdd = null
    },
    [actionTypes.CHECKOUT_DAY_INFO]: (state, action) => {
        state.checkoutInfo = action.data
    },
    [actionTypes.FREEZE_CHECK]: (state, action) => {
        state.cart = action.cart,
        state.updatedItems = action.updatedItems,
        state.removedItems = action.removedItems,
        state.showFreezePopup = action.showFreezePopup
    },
    [actionTypes.CLOSE_FREEZE_POPUP]: (state, action) => {
        state.updatedItems = action.updatedItems,
        state.removedItems = action.removedItems,
        state.showFreezePopup = action.showFreezePopup
    }
})

export default cartReducer;

