import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.scss';
import MainTemplate from './components/MainComponent/Template/MainTemplate';
import Home from './features/Home/Home';
import Cart from "./features/Cart/Cart"
import Signup from "./features/Signup/Signup"
import CartCreate from "./features/CartCreate/CartCreate"
import CartUpdate from './features/CartUpdate/CartUpdate';
import Stores from "./features/Stores/Stores"
import Product from "./features/Product/Product"
import Checkout from './features/Checkout/Checkout';
import Orders from './features/Orders/Orders';
import Favorites from './features/Favorites/Favorites';
import SearchCategory from './features/SearchCategory/NewSearchCategory';
import BannerProductList from './features/Home/HomeCarousel/BannerProductList';
import NewSmartList from './features/Smartlist/NewSmartList';
import NewSearch from './features/NewSearch/NewSearch';
import Payments from './features/Payments/Payments';
import Boarding from './features/Payments/Boarding/Boarding';
import PasswordReset from './features/PasswordReset/PasswordReset';
import ProfileContainer from './features/ProfileContainer/ProfileContainer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import NewProfileContainer from './features/ProfileContainer/NewProfileContainer';
import { authenticationService } from "./services/basis/AuthenticationService"
import config from "./config/config"

// custom router
import { Router } from 'react-router'
import createBrowserHistory from 'history/createBrowserHistory'

// redux
import * as actionCreators from "./redux/store/actions/index"
import { connect } from "react-redux"

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faPlus, faClipboardList, faCopy, faCreditCard,
  faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt, faPlusCircle, faEdit, faChevronRight, faChevronDown
} from '@fortawesome/free-solid-svg-icons'
import Faq from './features/Faq/Faq';
import Terms from './features/Terms/Terms';

library.add(fab, faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faClipboardList, faCopy, faChevronRight, faChevronDown,
  faPlus, faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt, faPlusCircle, faEdit, faCreditCard, fab.faFacebookSquare)

// custom history
export const history = createBrowserHistory({ basename: "/app" });

class App extends Component {
  componentDidMount() {
    if (this.props.user) {
      this.props.current();
      this.props.getSlists();
      this.props.getFavorites();
    }
    this.props.categories();
  }

  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
          <MainTemplate>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/faq' component={Faq} />
              <Route exact path='/termsandcondition' component={Terms} />
              <Route exact path='/cart' component={Cart} />
              <Route exact path='/cart/create' component={CartCreate} />
              <Route exact path='/cart/update' component={CartUpdate} />
              <Route exact path='/signup' component={Signup} />
              <Route exact path='/profile' component={NewProfileContainer} />
              <Route exact path='/profile/addresses' component={NewProfileContainer} />
              <Route exact path='/profile/billing' component={NewProfileContainer} />
              <Route exact path='/profile/password' component={NewProfileContainer} />
              <Route exact path='/reset' component={PasswordReset} />
              <Route exact path='/stores' component={Stores} />
              <Route exact path='/search' component={NewSearch} />
              <Route exact path='/profile/orders' component={NewProfileContainer} />
              <Route exact path='/profile/smartlists' component={NewProfileContainer} />
              <Route exact path='/profile/payments' component={NewProfileContainer} />
              <Route exact path='/favorites' component={Favorites} />
              <Route exact path='/payments' component={Payments} />
              <Route exact path='/payments/boarding' component={Boarding} />
              <Route exact path='/product/:barcode' component={Product} />
              <Route exact path='/category/:firstLevelCategory' component={SearchCategory} />
              <Route exact path='/category/:firstLevelCategory/:secondLevelCategory' component={SearchCategory} />
              <Route exact path='/category/:firstLevelCategory/:secondLevelCategory/:thirdLevelCategory' component={SearchCategory} />
              <Route exact path='/checkout' component={Checkout} />
              <Route exact path='/checkout/data' component={Checkout} />
              <Route exact path='/checkout/summary' component={Checkout} />
              <Route exact path='/checkout/confirm' component={Checkout} />
              <Route exact path='/checkout/failed' component={Checkout} />
              <Route exact path='/banners/:bannerId/products' component={BannerProductList} />
            </Switch>
          </MainTemplate>
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    current: () => dispatch(actionCreators.current()),
    availability: () => dispatch(actionCreators.availability()),
    logout: () => dispatch(actionCreators.logout()),
    getFavorites: () => dispatch(actionCreators.getFavorites()),
    getSlists: () => dispatch(actionCreators.getSlists()),
    categories: () => dispatch(actionCreators.categories()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
